const yalidineData = {
    'wilayas': {
      'Adrar': {
        code: '01',
        tarifs: {
          domicile: 1600,
          pointRelais: 800
        },
        bureaux: {
          'Adrar': [
            {
              id: '10101',
              nom: 'Agence de Adrar [Yalidine]',
              adresse: 'Rue Benhachem Maamar',
              commune: 'Adrar'
            }
          ],
          'Timimoun': [
            {
              id: '10901',
              nom: 'Agence de Timimoun [Yalidine]',
              adresse: 'Rue Mohamed El Hashemi',
              commune: 'Timimoun'
            }
          ]
        }
      },
      'Chlef': {
        code: '02',
        tarifs: {
          domicile: 900,
          pointRelais: 450
        },
        bureaux: {
          'Chlef': [
            {
              id: '21101',
              nom: 'Agence de Chlef [Yalidine]',
              adresse: 'route national N4 Chlef',
              commune: 'Chlef'
            },
            {
              id: '21102',
              nom: 'Agence de Chlef [Guepex]',
              adresse: 'Cité Cherifi Kadour, 113',
              commune: 'Chlef'
            }
          ],
          'Ténès': [
            {
              id: '23401',
              nom: 'Agence de Ténès [Guepex]',
              adresse: 'Bt A2 Rdc Local 28, Sortie Ouest Route De Mostaganem',
              commune: 'Ténès'
            }
          ]
        }
      },
      'Laghouat': {
        code: '03',
        tarifs: {
          domicile: 1200,
          pointRelais: 600
        },
        bureaux: {
          'Aflou': [
            {
              id: '31901',
              nom: 'Agence de Aflou [Yalidine]',
              adresse: 'Hai Zahi Ben Aissa, Route El Bayadh (à coté de la centrale de police)',
              commune: 'Aflou'
            }
          ],
          'Laghouat': [
            {
              id: '30102',
              nom: 'Agence Laghouat Cité OASIS [Easy and speed]',
              adresse: "Cité l'OASIS, centre ville Laghouat, Laghouat.",
              commune: 'Laghouat'
            }
          ]
        }
      },
      'Oum El Bouaghi': {
        code: '04',
        tarifs: {
          domicile: 900,
          pointRelais: 350
        },
        bureaux: {
          'Aïn M\'lila': [
            {
              id: '40301',
              nom: 'Agence de Aïn M\'lila [Yalidine]',
              adresse: 'Route Messas (à coté superette Amiche)',
              commune: 'Aïn M\'lila'
            }
          ],
          'Oum el Bouaghi': [
            {
              id: '40101',
              nom: 'Agence de Oum el Bouaghi [Yalidine]',
              adresse: 'Cité El Moustakbel',
              commune: 'Oum el Bouaghi'
            }
          ]
        }
      },
      'Batna': {
        code: '05',
        tarifs: {
          domicile: 900,
          pointRelais: 350
        },
        bureaux: {
          'Barika': [
            {
              id: '54201',
              nom: 'Agence de Barika [Guepex]',
              adresse: 'Boulevard Azil Abdul Rahman, Rue Les Freres Debache, Route De Batna',
              commune: 'Barika'
            }
          ],
          'Batna': [
            {
              id: '50101',
              nom: 'Agence des 500 Logements [Yalidine]',
              adresse: 'Lotissement Meddour (En Face Lycee 500 Logements)',
              commune: 'Batna'
            },
            {
              id: '50103',
              nom: 'Agence du CHU Route de Tazoult [Guepex]',
              adresse: 'Cité frères Lombarkia, ex parc à fourrage',
              commune: 'Batna'
            }
          ]
        }
      },
      'Béjaïa': {
        code: '06',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Akbou': [
            {
              id: '62501',
              nom: 'Agence de Akbou [Yalidine]',
              adresse: 'Cite 16 Logement Eplf',
              commune: 'Akbou'
            }
          ],
          'Béjaïa': [
            {
              id: '60101',
              nom: 'Agence de Béjaïa El Qods [Guepex]',
              adresse: '5 Rue Des Freres Taguelmint El Qods En Face De La Gare Routiere',
              commune: 'Béjaïa'
            },
            {
              id: '60102',
              nom: 'Agence de Béjaïa Edimco [Yalidine]',
              adresse: 'Zone Industrielle, Rue Mahfoudi Fateh, EDIMCO, (Derrière le centre commercial Ritadj-Mall)',
              commune: 'Béjaïa'
            }
          ],
          'El Kseur': [
            {
              id: '64001',
              nom: 'Agence de El Kseur [Guepex]',
              adresse: 'Rue Meziane Hmimi',
              commune: 'El Kseur'
            }
          ]
        }
      },
      'Biskra': {
        code: '07',
        tarifs: {
          domicile: 850,
          pointRelais: 350
        },
        bureaux: {
          'Biskra': [
            {
              id: '70401',
              nom: 'Agence de Biskra [Yalidine]',
              adresse: "Zone D'Équipement Biskra En Face La Maison Nissan.",
              commune: 'Biskra'
            },
            {
              id: '70402',
              nom: 'Agence zone ouest Biskra [Easy and speed]',
              adresse: 'coopératif du verger N°22 zone ouest Biskra',
              commune: 'Biskra'
            }
          ],
          'Ouled Djellal': [
            {
              id: '72601',
              nom: 'Agence de Ouled Djellal [Yalidine]',
              adresse: 'Rue Gasmi Ibrahim (En Face Ecole Mazen School)',
              commune: 'Ouled Djellal'
            }
          ],
          'Tolga': [
            {
              id: '73201',
              nom: 'Agence de Tolga [Guepex]',
              adresse: 'Cité Sidi Rouak rue Soltani Ahmed',
              commune: 'Tolga'
            }
          ]
        }
      },
      'Béchar': {
        code: '08',
        tarifs: {
          domicile: 1400,
          pointRelais: 800
        },
        bureaux: {
          'Béchar': [
            {
              id: '80101',
              nom: 'Agence de Béchar [Yalidine]',
              adresse: 'Cite Hai El Badr, Lot N°50, Secteur N°49, Local 01.',
              commune: 'Béchar'
            }
          ]
        }
      },
      'Blida': {
        code: '09',
        tarifs: {
          domicile: 800,
          pointRelais: 350
        },
        bureaux: {
          'Blida': [
            {
              id: '90101',
              nom: 'Agence de Blida [Yalidine]',
              adresse: 'La zone indistruelle Ben Boulaid (devant Family Shop)',
              commune: 'Blida'
            },
            {
              id: '90102',
              nom: 'Agence de Bab Dzair [Guepex]',
              adresse: "21 centre d'artisans, rue d'Alger",
              commune: 'Blida'
            }
          ],
          'Bouarfa': [
            {
              id: '92401',
              nom: 'Agence de Bouarfa [Yalidine]',
              adresse: 'Rue Principale, N°24.',
              commune: 'Bouarfa'
            }
          ],
          'Boufarik': [
            {
              id: '92001',
              nom: 'Agence de Boufarik [Guepex]',
              adresse: 'Rue Si Ben Youcef',
              commune: 'Boufarik'
            }
          ],
          'Larbaa': [
            {
              id: '92101',
              nom: 'Agence de Larbaa [Zimou-Express]',
              adresse: 'Rue El Emir Khaled',
              commune: 'Larbaa'
            }
          ]
        }
      },
      'Bouira': {
        code: '10',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Bouira': [
            {
              id: '101601',
              nom: 'Agence de Bouira [Yalidine]',
              adresse: "Lotissement Amar Khoudja B0 (A Gauche Du Nouveau Rond Point D'Aigle En Allant Vers Le Boulevard)",
              commune: 'Bouira'
            }
          ],
          'Lakhdaria': [
            {
              id: '103101',
              nom: 'Agence de Lakhdaria [Yalidine]',
              adresse: 'Rue du 5 JUILLET',
              commune: 'Lakhdaria'
            }
          ],
          'Sour El Ghouzlane': [
            {
              id: '104101',
              nom: 'Agence de Sour El Ghouzlane [Guepex]',
              adresse: 'N°03 Cite Sayeh, Section 13',
              commune: 'Sour El Ghouzlane'
            }
          ]
        }
      },




























      'Tamanrasset': {
        code: '11',
        tarifs: {
          domicile: 1600,
          pointRelais: 1000
        },
        bureaux: {
          'In Salah': [
            {
              id: '110801',
              nom: 'Agence de In Salah [Yalidine]',
              adresse: 'Centre ville (à coté de la poste et la maison de jeune)',
              commune: 'In Salah'
            }
          ],
          'Tamanrasset': [
            {
              id: '110101',
              nom: 'Agence de Tamanrasset [Guepex]',
              adresse: 'Hai Gataa El Oued',
              commune: 'Tamanrasset'
            }
          ]
        }
      },
      'Tébessa': {
        code: '12',
        tarifs: {
          domicile: 800,
          pointRelais: 600
        },
        bureaux: {
          'Tébessa': [
            {
              id: '120101',
              nom: 'Agence de Tébessa [Yalidine]',
              adresse: 'Boulevard Houari Boumediene (En Face De La Pharmacie Hazourli)',
              commune: 'Tébessa'
            },
            {
              id: '120102',
              nom: 'Agence de Skanska [Guepex]',
              adresse: 'Boulevard Chedli Ben Jdid, skanska',
              commune: 'Tébessa'
            }
          ]
        }
      },
      'Tlemcen': {
        code: '13',
        tarifs: {
          domicile: 900,
          pointRelais: 350
        },
        bureaux: {
          'Chetouane': [
            {
              id: '135001',
              nom: 'Agence Chetouane [Yalidine]',
              adresse: 'Zone indistruelle',
              commune: 'Chetouane'
            }
          ],
          'Maghnia': [
            {
              id: '132701',
              nom: 'Agence de Maghnia [Yalidine]',
              adresse: 'Tafna N°4 Cite Perri',
              commune: 'Maghnia'
            }
          ],
          'Mansourah': [
            {
              id: '135101',
              nom: 'Agence de Mansourah [Yalidine]',
              adresse: 'Boulevard Imama (en face la piscine olympique)',
              commune: 'Mansourah'
            }
          ],
          'Remchi': [
            {
              id: '130401',
              nom: 'Agence Remchi [Yalidine]',
              adresse: 'Rue Adjedir Mohamed',
              commune: 'Remchi'
            }
          ],
          'Tlemcen': [
            {
              id: '130101',
              nom: 'Agence de Tlemcen [guepex]',
              adresse: 'Rue Des Freres Bouafia N°3 (A Cote De La Banque Natexis)',
              commune: 'Tlemcen'
            }
          ]
        }
      },
      'Tiaret': {
        code: '14',
        tarifs: {
          domicile: 950,
          pointRelais: 400
        },
        bureaux: {
          'Tiaret': [
            {
              id: '143901',
              nom: 'Agence de Tiaret [Yalidine]',
              adresse: 'Rue police Amer, n 161',
              commune: 'Tiaret'
            }
          ]
        }
      },
      'Tizi Ouzou': {
        code: '15',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Azazga': [
            {
              id: '151801',
              nom: 'Agence de Azazga [Yalidine]',
              adresse: '01 Rue Djurdjura, Immeuble Ait Amara (Dépôt)',
              commune: 'Azazga'
            }
          ],
          'Draâ Ben Khedda': [
            {
              id: '154701',
              nom: 'Agence de Draâ Ben Khedda [Guepex]',
              adresse: 'Touares 01',
              commune: 'Draâ Ben Khedda'
            }
          ],
          'Tizi Gheniff': [
            {
              id: '151101',
              nom: 'Agence de Tizi Gheniff [Guepex]',
              adresse: 'Rue Fatoum Amar, Rez-De-Chaussee Btm 17, N° 02',
              commune: 'Tizi Gheniff'
            }
          ],
          'Tizi Ouzou': [
            {
              id: '150101',
              nom: 'Agence de Bekkar [Guepex]',
              adresse: 'Cite Bekkar Classe 78 Propriete Groupe 137 (En Face Placette)',
              commune: 'Tizi Ouzou'
            },
            {
              id: '150102',
              nom: 'Agence de nouvelle ville [Yalidine]',
              adresse: 'Nouvelle ville',
              commune: 'Tizi Ouzou'
            }
          ]
        }
      },
      'Alger': {
        code: '16',
        tarifs: {
          domicile: 750,
          pointRelais: 350
        },
        bureaux: {
          'Aïn Benian': [
            {
              id: '164401',
              nom: 'Agence de Aïn Benian [Guepex]',
              adresse: '19 Route Al-Jamilah, Division Kargon',
              commune: 'Aïn Benian'
            }
          ],
          'Alger Centre': [
            {
              id: '160101',
              nom: 'Sacré-Cœur [Guepex]',
              adresse: '116 Didouche Mourad, Sacre Cœur',
              commune: 'Alger Centre'
            }
          ],
          'Bab El Oued': [
            {
              id: '160501',
              nom: 'Agence de Bab El Oued [Guepex]',
              adresse: '107 Rue Colonel Lotfi',
              commune: 'Bab El Oued'
            }
          ],
          'Baraki': [
            {
              id: '161401',
              nom: 'Agence de Baraki [Guepex]',
              adresse: 'Route de Larbâa (à coté de la supérette Promo+)',
              commune: 'Baraki'
            }
          ],
          'Birkhadem': [
            {
              id: '161201',
              nom: 'Agence de Birkhadem [Yalidine]',
              adresse: 'Cite Vergers Villa N°1',
              commune: 'Birkhadem'
            }
          ],
          'Birtouta': [
            {
              id: '163401',
              nom: 'Agence de Birtouta [Guepex]',
              adresse: '06 rue El Moudjahid Hamida Mouhamed (à côté de Supermarquet Saadi)',
              commune: 'Birtouta'
            }
          ],
          'Bordj El Bahri': [
            {
              id: '163901',
              nom: 'Agence Bordj El Bahri [Yalidine]',
              adresse: 'Projet 1000 LSP 47, lot 96, 97 et 98',
              commune: 'Bordj El Bahri'
            }
          ],
          'Bordj El Kiffan': [
            {
              id: '163001',
              nom: 'Agence de Bordj El Kiffan [Yalidine]',
              adresse: 'Rue 1Er Novembre 26 Cite Mimouni N 04',
              commune: 'Bordj El Kiffan'
            }
          ],
          'Cheraga': [
            {
              id: '165001',
              nom: 'Dar Diaf [Yalidine]',
              adresse: 'Dar Diaf (à Côté De La Supérette Sedda)',
              commune: 'Cheraga'
            },
            {
              id: '165002',
              nom: 'Amara [Yalidine]',
              adresse: 'Micro Zone',
              commune: 'Cheraga'
            }
          ],
          'Dar El Beïda': [
            {
              id: '162003',
              nom: "Agence d'El Hamiz [Easy & Speed]",
              adresse: 'Cité les orangers Groupe A villa N°431, El Hamiz (en face le stade)',
              commune: 'Dar El Beïda'
            }
          ],
          'Douera': [
            {
              id: '165401',
              nom: 'Agence de Douera [SpeedMail]',
              adresse: 'cité 3746 LOGTS AADL-BT 88 Local 01',
              commune: 'Douera'
            }
          ],
          'Draria': [
            {
              id: '165301',
              nom: 'Agence de Draria [Guepex]',
              adresse: 'Cite Darbush 145, Habitation 400, Batiment 2',
              commune: 'Draria'
            }
          ],
          'Hussein Dey': [
            {
              id: '161701',
              nom: 'Hussein Dey [Yalidine]',
              adresse: 'Route Tripoli N°152',
              commune: 'Hussein Dey'
            }
          ],
          'Les Eucalyptus': [
            {
              id: '163302',
              nom: 'Agence Les Eucalyptus [Zimou-Express]',
              adresse: 'Rue Les Eucalyptus, N°56, palais rouge',
              commune: 'Les Eucalyptus'
            }
          ],
          'Ouled Fayet': [
            {
              id: '165101',
              nom: 'Agence de Ouled Fayet [Guepex]',
              adresse: '19 route du stade communal',
              commune: 'Ouled Fayet'
            }
          ],
          'Reghaïa': [
            {
              id: '164301',
              nom: 'Agence de DNC [Yalidine]',
              adresse: 'Cité El Ouancharis, DNC, Route de Ain Taya (En face Société générale)',
              commune: 'Reghaïa'
            },
            {
              id: '164302',
              nom: 'Agence de Signa [Guepex]',
              adresse: 'Cité Signa',
              commune: 'Reghaïa'
            }
          ],
          'Rouïba': [
            {
              id: '164201',
              nom: 'Agence de Rouiba [Zimou-Express]',
              adresse: 'Rue Hassiba Ben Bouali (chemin polyclinique)',
              commune: 'Rouïba'
            }
          ],
          'Zeralda': [
            {
              id: '164601',
              nom: 'Agence de Zeralda [Guepex]',
              adresse: 'Local Commercial Rez De Chausser , Cite Yesswel Kouider N°01',
              commune: 'Zeralda'
            }
          ]
        }
      },
      



      'Djelfa': {
        code: '17',
        tarifs: {
          domicile: 1200,
          pointRelais: 600
        },
        bureaux: {
          'Aïn Oussara': [
            {
              id: '170501',
              nom: 'Agence de Aïn Oussara [Yalidine]',
              adresse: 'Cité Mohamed Boudiaf (rue en face la BNA, à coté de douche Rebhi)',
              commune: 'Aïn Oussara'
            }
          ],
          'Djelfa': [
            {
              id: '171401',
              nom: 'Agence de Djelfa [Yalidine]',
              adresse: 'Cité Boutrifis, route de Boussaâda, lotissement 137 N 06 Immeuble Djouaf Ameur',
              commune: 'Djelfa'
            }
          ]
        }
      },
      'Jijel': {
        code: '18',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'El Milia': [
            {
              id: '180901',
              nom: 'Agence de El Milia [Guepex]',
              adresse: 'Rue Boulakirba Zidane',
              commune: 'El Milia'
            }
          ],
          'Jijel': [
            {
              id: '180101',
              nom: 'Agence de Jijel [Yalidine]',
              adresse: 'Village Mustapha',
              commune: 'Jijel'
            }
          ],
          'Taher': [
            {
              id: '180501',
              nom: 'Agence de Taher [Guepex]',
              adresse: 'Immeuble Des Bailleurs, Section 58, Groupement De Propriete N°111, Quartier Zemouch',
              commune: 'Taher'
            }
          ]
        }
      },
      'Sétif': {
        code: '19',
        tarifs: {
          domicile: 850,
          pointRelais: 350
        },
        bureaux: {
          'Aïn Oulmene': [
            {
              id: '190701',
              nom: 'Agence de Aïn Oulmene [Guepex]',
              adresse: 'Cite 113, Immeuble Residentiel 63, Batiment 1 Rez-De-Chaussee, Entree 3',
              commune: 'Aïn Oulmene'
            }
          ],
          'Bougaa': [
            {
              id: '192601',
              nom: 'Agence de Bougaa [Guepex]',
              adresse: 'Rue Mohamed Chinoune',
              commune: 'Bougaa'
            }
          ],
          'El Eulma': [
            {
              id: '193202',
              nom: 'Desk El Eulma [Yalidine]',
              adresse: 'Rue Abdelaziz Khaled, n°119 (à côté du hôtel El Bez)',
              commune: 'El Eulma'
            }
          ],
          'Sétif': [
            {
              id: '195501',
              nom: 'Maabouda [Yalidine]',
              adresse: "Cite D'Al-Ma'Bouda, Escalier 1 Du Batiment B Section 203 Groupe 77 Parcelle 5",
              commune: 'Sétif'
            },
            {
              id: '195502',
              nom: 'El Hidhab [Guepex]',
              adresse: 'Cité El Hidhab, 110 Logements Participatifs, Bâtiment B, Classe 71',
              commune: 'Sétif'
            }
          ]
        }
      },
      'Saïda': {
        code: '20',
        tarifs: {
          domicile: 1000,
          pointRelais: 400
        },
        bureaux: {
          'Saïda': [
            {
              id: '201101',
              nom: 'Agence de Saïda [Yalidine]',
              adresse: 'cité Riadh (à coté de la mosquée Riadh)',
              commune: 'Saïda'
            }
          ]
        }
      },
































      'Skikda': {
        code: '21',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Azzaba': [
            {
              id: '210501',
              nom: 'Agence de Azzaba [Guepex]',
              adresse: "Enseigne Cite Za'Af Rabeh",
              commune: 'Azzaba'
            }
          ],
          'Collo': [
            {
              id: '211401',
              nom: 'Agence de Collo [Guepex]',
              adresse: 'Boulevard Ruwaibah Taher',
              commune: 'Collo'
            }
          ],
          'El Harrouch': [
            {
              id: '211801',
              nom: 'Agence de El Harrouch [Guepex]',
              adresse: 'Cite 24 Logements Sociaux Covalent Sonatiba',
              commune: 'El Harrouch'
            }
          ],
          'Skikda': [
            {
              id: '213502',
              nom: 'Nouvelle Agence Skikda [Yalidine]',
              adresse: 'Rue El Reboua El Djamila, partie N°01 rez-de-chaussée',
              commune: 'Skikda'
            }
          ]
        }
      },




      'Sidi Bel Abbès': {
        code: '22',
        tarifs: {
          domicile: 900,
          pointRelais: 400
        },
        bureaux: {
          'Sidi Bel Abbes': [
            {
              id: '223601',
              nom: 'Agence de Sidi Bel Abbes [Yalidine]',
              adresse: "Rue Mascara, (à cote de l'hotel Beni Talla)",
              commune: 'Sidi Bel Abbes'
            },
            {
              id: '223602',
              nom: 'Benhamouda [Guepex]',
              adresse: 'Benhamouda, (monté Sogral avant la mosquée El Safaa)',
              commune: 'Sidi Bel Abbes'
            }
          ]
        }
      },
      'Annaba': {
        code: '23',
        tarifs: {
          domicile: 850,
          pointRelais: 350
        },
        bureaux: {
          'Annaba': [
            {
              id: '230101',
              nom: 'Agence de Gassiot [Yalidine]',
              adresse: 'Ancien Tls, Boulerard 28 Janvier 1957',
              commune: 'Annaba'
            },
            {
              id: '230102',
              nom: 'Agence de Sidi Brahim [Guepex]',
              adresse: 'Champs de mars numéro n°3',
              commune: 'Annaba'
            }
          ],
          'El Bouni': [
            {
              id: '230501',
              nom: 'Agence de El Bouni [Guepex]',
              adresse: 'Section 1 Groupe 63 Propriete 246',
              commune: 'El Bouni'
            }
          ]
        }
      },
      'Guelma': {
        code: '24',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Guelma': [
            {
              id: '241901',
              nom: 'Nouvelle Agence Guelma [Yalidine]',
              adresse: 'route Ain Larbi, local N°01',
              commune: 'Guelma'
            }
          ],
          'Oued Zenati': [
            {
              id: '243001',
              nom: 'Agence de Oued Zenati [Guepex]',
              adresse: 'Magasin Num 02',
              commune: 'Oued Zenati'
            }
          ]
        }
      },
      'Constantine': {
        code: '25',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Constantine': [
            {
              id: '250401',
              nom: 'Agence Belle vue [Yalidine]',
              adresse: 'Route du Stade',
              commune: 'Constantine'
            },
            {
              id: '250402',
              nom: 'Agence Sidi Mabrouk [Guepex]',
              adresse: 'نهج لعراقب احمد رقم 09',
              commune: 'Constantine'
            }
          ],
          'Didouche Mourad': [
            {
              id: '250501',
              nom: 'Agence de Didouche Mourad [Yalidine]',
              adresse: 'El Riadh n°10',
              commune: 'Didouche Mourad'
            }
          ],
          'El Khroub': [
            {
              id: '250601',
              nom: "Agence d'El Khroub [Guepex]",
              adresse: 'Cité Bouhali Mohamed Said El Khroub',
              commune: 'El Khroub'
            },
            {
              id: '250602',
              nom: 'Agence Ali Mendjeli [Yalidine]',
              adresse: "Zone d'Activité N°47 Nouvelle Ville, Ali MENDJLI",
              commune: 'El Khroub'
            },
            {
              id: '250605',
              nom: 'Agence de Ali Mendjli [Guepex]',
              adresse: '650 lpa عمارة 24 زاوية محل 145',
              commune: 'El Khroub'
            }
          ]
        }
      },

      'Médéa': {
        code: '26',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Médéa': [
            {
              id: '263301',
              nom: 'Agence de Médéa [El Koutab] [Yalidine]',
              adresse: 'Hai El Koutab Médéa (En face boutique Dyelna Shop)',
              commune: 'Médéa'
            },
            {
              id: '263302',
              nom: 'Agence de Médéa [Pole Urbain] [Guepex]',
              adresse: 'Cité 80 Logs LSP Pole Urbain (à coté de la station de service Benhafri)',
              commune: 'Médéa'
            }
          ],
          'Tablat': [
            {
              id: '265901',
              nom: 'Agence Tablat [Yalidine]',
              adresse: 'rue nationale N 08',
              commune: 'Tablat'
            }
          ]
        }
      },

      'Mostaganem': {
        code: '27',
        tarifs: {
          domicile: 900,
          pointRelais: 400
        },
        bureaux: {
          'Mostaganem': [
            {
              id: '271801',
              nom: 'Salamandre [Yalidine]',
              adresse: 'Salamandre (Rond Point De La Douane)',
              commune: 'Mostaganem'
            },
            {
              id: '271802',
              nom: 'Kharouba [Guepex]',
              adresse: 'Cité 144 logement',
              commune: 'Mostaganem'
            }
          ]
        }
      },

      'M\'Sila': {
        code: '28',
        tarifs: {
          domicile: 900,
          pointRelais: 350
        },
        bureaux: {
          'Berhoum': [
            {
              id: '281001',
              nom: 'Agence de Berhoum [Guepex]',
              adresse: 'محل رقم 03 مجموعة ملكية 333 قسم 02 حي زيغود يوسف بلدية برهوم',
              commune: 'Berhoum'
            }
          ],
          'Bou Saâda': [
            {
              id: '281201',
              nom: 'Agence de Bou Saâda [Yalidine]',
              adresse: 'Cite 20 Aout 636/N°05 A',
              commune: 'Bou Saâda'
            }
          ],
          'M\'Sila': [
            {
              id: '282802',
              nom: 'Agence Salem Shopping Mall [Yalidine]',
              adresse: 'Salem Shopping Mall, centre commercial (en face la daïra)',
              commune: 'M\'Sila'
            }
          ]
        }
      },
      'Mascara': {
        code: '29',
        tarifs: {
          domicile: 950,
          pointRelais: 400
        },
        bureaux: {
          'Mascara': [
            {
              id: '292801',
              nom: 'Agence de Mascara [Yalidine]',
              adresse: 'Rue Hamdani Adda  Rue Khasibiya No. 9, Magasin 1 Et 2',
              commune: 'Mascara'
            }
          ]
        }
      },

      'Ouargla': {
        code: '30',
        tarifs: {
          domicile: 1200,
          pointRelais: 600
        },
        bureaux: {
          'Hassi Messaoud': [
            {
              id: '300801',
              nom: 'Agence de Hassi Messaoud [Yalidine]',
              adresse: 'Derrière la CNAS, à coté de la clinique Ibn Sina',
              commune: 'Hassi Messaoud'
            }
          ],
          'Ouargla': [
            {
              id: '301301',
              nom: 'Agence de Ouargla [Guepex]',
              adresse: 'Rue Lahreche Bachir (en face Dr. Abdelkader Beddiaf et la pharmacie Hakoum)',
              commune: 'Ouargla'
            }
          ],
          'Touggourt': [
            {
              id: '302001',
              nom: 'Agence de Touggourt [Yalidine]',
              adresse: 'Cite Rimal 01 (A Cote De La Pharmacie Harkati Route Nationale N°03)',
              commune: 'Touggourt'
            }
          ]
        }
      },































      'Oran': {
        code: '31',
        tarifs: {
          domicile: 900,
          pointRelais: 350
        },
        bureaux: {
          'Arzew': [
            {
              id: '310601',
              nom: 'Agence de Arzew [Guepex]',
              adresse: '17 Lotissement N 66 Plan Lot 167',
              commune: 'Arzew'
            }
          ],
          'Bir El Djir': [
            {
              id: '310301',
              nom: 'El Morchid [Yalidine]',
              adresse: 'Cooperative Immobiliere Dar El Amel N°68',
              commune: 'Bir El Djir'
            },
            {
              id: '310302',
              nom: 'Agence Fernand Ville [Yalidine]',
              adresse: '65 Rue 1er Novembre Hai Khemisti ,part 4 (Mosquée el kods)',
              commune: 'Bir El Djir'
            }
          ],
          'Oran': [
            {
              id: '310101',
              nom: 'Saint Hubert [Yalidine]',
              adresse: "Rue de l'ANP Projet rue 153 GV 50-89 part 2 et 3 (Entre CPA ET CASNOS)",
              commune: 'Oran'
            },
            {
              id: '310102',
              nom: 'Cité Djamel [Guepex]',
              adresse: 'Rond-point cité Djamel (en allant vers Hai Sabah)',
              commune: 'Oran'
            },
            {
              id: '310103',
              nom: 'Agence de Gambetta [Zimou-Express]',
              adresse: "54 avenue d'arcole, Bouguerri Khelifa, Gambetta",
              commune: 'Oran'
            },
            {
              id: '310104',
              nom: 'Agence Canastel [speedmail]',
              adresse: '25, Rue Akid Lotfi Local N° 02',
              commune: 'Oran'
            }
          ]
        }
      },
      'El Bayadh': {
        code: '32',
        tarifs: {
          domicile: 1400,
          pointRelais: 600
        },
        bureaux: {
          'El Bayadh': [
            {
              id: '320101',
              nom: 'Agence de El Bayadh [Yalidine]',
              adresse: 'Rue Mohamed Touil (A Cote Auberge Hanna)',
              commune: 'El Bayadh'
            }
          ]
        }
      },
      'Illizi': {
        code: '33',
        tarifs: {
          domicile: 1800,
          pointRelais: 1200
        },
        bureaux: {
          'Djanet': [
            {
              id: '330201',
              nom: 'Agence de Djanet [Guepex]',
              adresse: 'Tin Khatma',
              commune: 'Djanet'
            }
          ],
          'Illizi': [
            {
              id: '330101',
              nom: 'Agence de Illizi [Guepex]',
              adresse: 'Chemin Ain El Kours Cite Salam',
              commune: 'Illizi'
            }
          ]
        }
      },
      'Bordj Bou Arreridj': {
        code: '34',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Bordj Bou Arreridj': [
            {
              id: '340601',
              nom: 'Agence de El Djebasse [Yalidine]',
              adresse: 'cite 1er novembre 1954, 90 logements numéro 42',
              commune: 'Bordj Bou Arreridj'
            },
            {
              id: '340602',
              nom: 'Agence Cité Soualem [Guepex]',
              adresse: 'Cité 01 novembre, rue de Sétif',
              commune: 'Bordj Bou Arreridj'
            }
          ]
        }
      },
      'Boumerdès': {
        code: '35',
        tarifs: {
          domicile: 650,
          pointRelais: 400
        },
        bureaux: {
          'Bordj Menaiel': [
            {
              id: '350501',
              nom: 'Agence de Bordj Menaiel [Guepex]',
              adresse: 'Rue Bouira Boualem, Section 02 Magasin 02',
              commune: 'Bordj Menaiel'
            }
          ],
          'Boumerdes': [
            {
              id: '350101',
              nom: 'Agence de Boumerdes [Yalidine]',
              adresse: 'Cité Foés bâtiment B local N°04',
              commune: 'Boumerdes'
            }
          ]
        }
      },
      'El Tarf': {
        code: '36',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'El Tarf': [
            {
              id: '361701',
              nom: 'Agence de El Tarf [Yalidine]',
              adresse: 'Rue N°44 Cite Les Vergers',
              commune: 'El Tarf'
            }
          ]
        }
      },
      'Tindouf': {
        code: '37',
        tarifs: {
          domicile: 1600,
          pointRelais: 1000
        },
        bureaux: {
          'Tindouf': [
            {
              id: '370201',
              nom: 'Agence de Tindouf [Yalidine]',
              adresse: 'Cites Moussani (A Cote De La Radio)',
              commune: 'Tindouf'
            }
          ]
        }
      },
      'Tissemsilt': {
        code: '38',
        tarifs: {
          domicile: 950,
          pointRelais: 400
        },
        bureaux: {
          'Tissemsilt': [
            {
              id: '382101',
              nom: 'Agence de Tissemsilt [Yalidine]',
              adresse: 'Rue Bouis Ali Num 33 B Section 067 Groupe De Propriete 048',
              commune: 'Tissemsilt'
            }
          ]
        }
      },
      'El Oued': {
        code: '39',
        tarifs: {
          domicile: 1000,
          pointRelais: 600
        },
        bureaux: {
          'Djamaa': [
            {
              id: '392801',
              nom: 'Agence de Djamaa [Guepex]',
              adresse: 'Cite Essalam (A Cote Du Clinique Douaa)',
              commune: 'Djamaa'
            }
          ],
          'El M\'Ghair': [
            {
              id: '392701',
              nom: 'Agence de El M\'Ghair [Yalidine]',
              adresse: 'Lotissement 360 Logements Nouvelle Zone Urbaine',
              commune: 'El M\'Ghair'
            }
          ],
          'El Oued': [
            {
              id: '390101',
              nom: 'Agence de El Oued [Yalidine]',
              adresse: 'Cite El Moudjahidine (en face Pharmacie Daghoum)',
              commune: 'El Oued'
            }
          ]
        }
      },

      'Khenchela': {
        code: '40',
        tarifs: {
          domicile: 800,
          pointRelais: 350
        },
        bureaux: {
          'Khenchela': [
            {
              id: '401301',
              nom: 'Agence de Khenchela [Yalidine]',
              adresse: 'Cite Du 1Er Novembre Route Des Poids Lourds (En Face Sonelgaz)',
              commune: 'Khenchela'
            }
          ]
        }
      },

































      'Souk Ahras': {
        code: '41',
        tarifs: {
          domicile: 850,
          pointRelais: 400
        },
        bureaux: {
          'Souk Ahras': [
            {
              id: '410101',
              nom: 'Agence de Souk Ahras [Yalidine]',
              adresse: 'Cité El Louz Lot 64 (les amandiers)',
              commune: 'Souk Ahras'
            }
          ]
        }
      },
      'Tipaza': {
        code: '42',
        tarifs: {
          domicile: 850,
          pointRelais: 350
        },
        bureaux: {
          'Cherchell': [
            {
              id: '422201',
              nom: 'Agence Cherchell [Guepex]',
              adresse: 'N°05 Rue MELHANI ABDERRAHMAN',
              commune: 'Cherchell'
            }
          ],
          'Hadjout': [
            {
              id: '421201',
              nom: 'Agence Hadjout [EasyAndspeed]',
              adresse: 'Rue de stade RDC Hadjout, Tipaza',
              commune: 'Hadjout'
            }
          ],
          'Koléa': [
            {
              id: '423501',
              nom: 'agence De Kolea [Yalidine]',
              adresse: 'N 03 Rue Mohamed addam',
              commune: 'Koléa'
            }
          ],
          'Tipaza': [
            {
              id: '420101',
              nom: 'Agence de Tipaza [Yalidine]',
              adresse: 'cite M\'hamed Bougara (El Garari)',
              commune: 'Tipaza'
            }
          ]
        }
      },
      'Mila': {
        code: '43',
        tarifs: {
          domicile: 800,
          pointRelais: 350
        },
        bureaux: {
          'Chelghoum Laid': [
            {
              id: '430801',
              nom: 'Agence de Chelghoum Laid [Yalidine]',
              adresse: 'Rue 01 Novembre, A Côté D\'Hôtel El Rhumel',
              commune: 'Chelghoum Laid'
            }
          ],
          'Mila': [
            {
              id: '431601',
              nom: 'Agence de Mila [Yalidine]',
              adresse: 'Rue De Zerghia (A Coté Du Point De Vente Iris Et Gam Assurance)',
              commune: 'Mila'
            }
          ]
        }
      },
      'Aïn Defla': {
        code: '44',
        tarifs: {
          domicile: 900,
          pointRelais: 400
        },
        bureaux: {
          'Aïn Defla': [
            {
              id: '440101',
              nom: 'Agence de Aïn Defla [Guepex]',
              adresse: "Cite Khyat Mohammed (L'Arret De La Zone Industrielle En Face La Pompe d'essence)",
              commune: 'Aïn Defla'
            }
          ],
          'Khemis Miliana': [
            {
              id: '442601',
              nom: 'Agence de Khemis Miliana [Guepex]',
              adresse: 'Le Cote Ouest Du Quartier La Cadette Route Nationale',
              commune: 'Khemis Miliana'
            }
          ]
        }
      },
      'Naâma': {
        code: '45',
        tarifs: {
          domicile: 1400,
          pointRelais: 800
        },
        bureaux: {
          'Mecheria': [
            {
              id: '450201',
              nom: 'Agence de Mecheria [Guepex]',
              adresse: 'Rue Abdellaoui Mohammed Cité Iben Badis (Connue Hadj Taleb, Près De Pharmacie Abbad Amina Et École Primaire)',
              commune: 'Mecheria'
            }
          ]
        }
      },
      'Aïn Témouchent': {
        code: '46',
        tarifs: {
          domicile: 950,
          pointRelais: 400
        },
        bureaux: {
          'Aïn Témouchent': [
            {
              id: '460401',
              nom: 'Agence de Aïn Témouchent [Yalidine]',
              adresse: 'Sonne n5/ n 605/ d 228 logements, Hai Zitoun (à coté de la nouvelle agence de transport)',
              commune: 'Aïn Témouchent'
            }
          ],
          'Beni Saf': [
            {
              id: '460701',
              nom: 'Agence de Beni Saf [Guepex]',
              adresse: "Cité Sohbi, zone urbaine (en face de la pompe d'essence)",
              commune: 'Beni Saf'
            }
          ]
        }
      },

      'Ghardaïa': {
        code: '47',
        tarifs: {
          domicile: 1200,
          pointRelais: 600
        },
        bureaux: {
          'El Menia': [
            {
              id: '470601',
              nom: 'Agence de El Menia [Yalidine]',
              adresse: 'Route Unite Africaine',
              commune: 'El Menia'
            }
          ],
          'Ghardaïa': [
            {
              id: '470701',
              nom: 'Agence de Ghardaïa [Guepex]',
              adresse: 'Cité Djamel Bouhraoua (en face palais des expositions)',
              commune: 'Ghardaïa'
            },
            {
              id: '470702',
              nom: 'Agence de Bouhraoua [Yalidine]',
              adresse: 'Bouhraoua (en face centre d\'exposition)',
              commune: 'Ghardaïa'
            }
          ]
        }
      },
      'Relizane': {
        code: '48',
        tarifs: {
          domicile: 950,
          pointRelais: 400
        },
        bureaux: {
          'Oued Rhiou': [
            {
              id: '482501',
              nom: 'Agence d\'Oued Rhiou [Guepex]',
              adresse: 'Rue Belhadj Tami (en face du centre de formation paramédical)',
              commune: 'Oued Rhiou'
            }
          ],
          'Relizane': [
            {
              id: '483001',
              nom: 'Agence de Relizane [Yalidine]',
              adresse: 'Boulevard 69 Zaghloul (en face la banque d\'Algérie)',
              commune: 'Relizane'
            }
          ]
        }
      }
    }
  };
  
  export default yalidineData;



  