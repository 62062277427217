import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        totalPrice: 0,
    },
    reducers: {
      addItemToCart: (state, action) => {
        const newItem = action.payload; // assuming payload contains image, title, color, size, price, quantity, and possibly sold
        const existingItem = state.items.find(
            (item) =>
                item.id === newItem.id &&
                item.color === newItem.color &&
                item.size === newItem.size
        );
    
        // Calculate item price after applying discount if "sold" exists
        const itemPrice = newItem.sold ? newItem.price * (1 - newItem.sold / 100) : newItem.price;
    
        if (existingItem) {
            existingItem.quantity += newItem.quantity; // Update quantity if item exists in cart
        } else {
            state.items.push(newItem); // Add as a new item if it doesn’t exist
        }
    
        // Update total price using the discounted or regular price
        state.totalPrice += itemPrice * newItem.quantity;
    },
        incrementQuantity: (state, action) => {
            const item = state.items.find((i) => i.id === action.payload);
            if (item) {
              item.quantity += 1;
            }
          },
          decrementQuantity: (state, action) => {
            const item = state.items.find((i) => i.id === action.payload);
            if (item && item.quantity > 1) {
              item.quantity -= 1;
            }
          },
          removeFromCart: (state, action) => {
            state.items = state.items.filter((i) => i.id !== action.payload);
          },
        // Other reducers (e.g., removeItem, clearCart, updateQuantity)
        clearCart: (state) => {
          state.items = [];
        }
    },
    // Dans cartSlice.js
    
})

export const { addItemToCart, incrementQuantity, decrementQuantity, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;