import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-black text-white pb-2 pt-11 px-8 lg:px-36 lg:mt-24 mt-12">
        <div className="container mx-auto flex flex-col lg:flex-row items-start justify-between">
            <div className="mb-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-white mb-0 flex">
                    Boutique Wanted
                    <img 
                    src="/logo.jpg" 
                    alt="Wanted Logo"
                    className="h-10 ml-4 w-auto rounded-full" 
                    />
                </h2>
                {/* <p className="text-sm mb-4">les dahlias - Tlemcen en face café si Boumediene, 13000</p>
                <p className="text-sm">livraison disponible 58 Wilaya</p> */}
            </div>

            {/* <div className="lg:ml-8">
                <h3 className="text-lg font-semibold text-white mb-3">Nos Réseaux Sociaux</h3>
                <hr className="border-gray-500 w-42 mb-3" />
                <div className="flex space-x-4">
                    <a href="https://www.facebook.com/wantedtlemcen" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                        <FontAwesomeIcon icon={faFacebook} className="text-white hover:text-gray-400 text-2xl" />
                    </a>
                    <a href="https://www.instagram.com/boutique__wanted/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FontAwesomeIcon icon={faInstagram} className="text-white hover:text-gray-400 text-2xl" />
                    </a>
                    <a href="https://www.tiktok.com/@boutiquewanted/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FontAwesomeIcon icon={faTiktok} className="text-white hover:text-gray-400 text-2xl" />
                    </a>
                </div>
            </div> */}
        </div>
    </footer>
  );
};

export default Footer;
