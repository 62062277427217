import React from 'react';
import { Link } from 'react-router-dom';
import AnimatedButton from './AnimatedButton';

const Header = () => {
  return (
    <header className="bg-white lg:px-32 lg:py-6 py-5 px-5 flex justify-between items-center sticky top-0 z-50 lg:mr-8">
      <h1 className="text-2xl font-bold">Wanted </h1>
      <nav className="lg:space-x-3 space-x-1 lg:text-lg lg:font-medium">
        <Link to="/admin" className="hover:text-gray-600">
          <AnimatedButton>Home</AnimatedButton>
        </Link>
        <Link to="/adminProduct" className="hover:text-gray-600">
          <AnimatedButton>Produits</AnimatedButton>
        </Link>
        <Link to="/adminOrder" className="hover:text-gray-600">
          <AnimatedButton>Commandes</AnimatedButton>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
