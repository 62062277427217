import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';

const OrderAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [expandedOrder, setExpandedOrder] = useState(null);

  // Fetch orders from the backend
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://boutiquewanted.com/api/orders');
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrders();
  }, []);

  // Filter orders based on confirmation status
  const filteredOrders = orders.filter(order => 
    showConfirmed ? order.state === 'confirmed' : order.state !== 'confirmed'
  );

  // Confirm order function
  const handleConfirmOrder = async (orderId) => {
    try {
      const response = await axios.put(`https://boutiquewanted.com/api/orders/${orderId}`, {
        state: 'confirmed'
      });

      if (response.status === 200) {
        const updatedOrders = orders.map((order) =>
          order._id === orderId ? { ...order, state: 'confirmed' } : order
        );
        setOrders(updatedOrders);
      }
    } catch (error) {
      console.error('Error confirming the order:', error);
    }
  };

  // Delete order function
  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(`https://boutiquewanted.com/api/orders/${orderId}`);

      if (response.status === 200) {
        setOrders(orders.filter((order) => order._id !== orderId));
      }
    } catch (error) {
      console.error('Error deleting the order:', error);
    }
  };

  // Toggle order details visibility
  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  return (
    <div className="order-admin my-8 mb-24 px-4 sm:mx-32">
      <h1 className="text-3xl font-semibold mb-6">Gestion des commandes</h1>

      {/* Filter Button */}
      <button
        onClick={() => setShowConfirmed(!showConfirmed)}
        className="bg-blue-500 text-white py-2 px-4 rounded mb-4 hover:bg-blue-600 transition"
      >
        {showConfirmed ? 'Afficher les commandes non confirmées' : 'Afficher les commandes confirmées'}
      </button>

      {/* Order count */}
      <p className="mb-4 text-gray-600">
        {filteredOrders.length} commande{filteredOrders.length !== 1 ? 's' : ''} {showConfirmed ? 'confirmée' : 'non confirmée'}{filteredOrders.length !== 1 ? 's' : ''}
      </p>

      {/* Order list */}
      <div className="order-list">
        {filteredOrders.length === 0 ? (
          <p className="text-center py-8 bg-gray-50 rounded-lg">
            Aucune commande {showConfirmed ? 'confirmée' : 'non confirmée'} disponible.
          </p>
        ) : (
          filteredOrders.map((order) => (
            <div key={order.orderId} className={`order-item bg-white lg:p-4 p-2 mb-4 rounded-lg shadow-md ${order.state === 'confirmed' ? 'border-l-4 border-green-500' : ''}`}>
              <div className="flex gap-2 lg:gap-0 lg:justify-between items-center">
                <div className='w-2/3 flex justify-between items-center'>
                  <h2 className="flex text-lg font-semibold mr-2 lg:mr-0">
                    <p className='lg:block hidden'>Commande &nbsp;</p> 
                    {order.orderId}
                  </h2>
                  <p className="lg:text-base text-xs">{order.firstname} {order.familyname}</p>
                  <p className="">{order.to_wilaya_name}</p>
                </div>

                <div className="w-2/3 flex justify-between items-center lg:w-[25%] w-full lg:pl-0 pl-2">
                  <p className="lg:text-lg font-semibold lg:text-base text-xs lg:mr-0 mr-2">{order.price} DA</p>
                  <div>
                    {!showConfirmed && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-green-500 cursor-pointer lg:mr-4 mr-2 hover:text-green-600 transition"
                        onClick={() => handleConfirmOrder(order._id)}
                      />
                    )}
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-500 cursor-pointer lg:mr-0 mr-2 hover:text-red-600 transition"
                      onClick={() => handleDeleteOrder(order._id)}
                    />
                  </div>
                  <button
                    onClick={() => toggleOrderDetails(order.orderId)}
                    className="text-blue-500 hover:text-blue-600 transition lg:ml-8"
                  >
                    {expandedOrder === order.orderId ? 'Fermer' : 'Détails'}
                  </button>
                </div>
              </div>

              {/* Order details section */}
              {expandedOrder === order.orderId && (
                <div className="order-details mt-4 border-t pt-4">
                  <p className="text-gray-600">Adresse: {order.address}</p>
                  <p className="text-gray-600">Commune: {order.to_commune_name}</p>
                  <p className="text-gray-600">Prix de Livraison: {order.livraison_cost} DA</p>
                  <p className="text-gray-600">
                    Téléphone: <a href={`tel:${order.contact_phone}`} className="text-blue-600 hover:underline">
                      {order.contact_phone}
                    </a>
                  </p>
                  
                  <h3 className="text-md font-semibold mt-2">Articles:</h3>
                  {order.items.map((item, index) => (
                    <div key={index} className="item-details p-2 bg-gray-50 rounded mb-2 flex justify-between items-center hover:bg-gray-100 transition">
                      <div className="flex-1">
                        <p>Produit: {item.title}</p>
                        <p>Quantité: {item.quantity}</p>
                        <p>Prix: {item.price} DA</p>
                        <p>Couleur: {item.color}</p>  
                        <p>Taille: {item.size}</p>  
                      </div>
                      <div className="ml-4">
                        {item.image && (
                          <img src={item.image} alt={item.title} className="w-24 h-24 object-cover rounded" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OrderAdmin;