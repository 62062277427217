// src/components/AdminRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const AdminRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially unknown

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Make a request to verify if the user is authenticated
        await axios.get('https://boutiquewanted.com/api/authRoutes/admin/verify', { withCredentials: true });
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false); // Not authenticated if an error occurs
      }
    };
    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Loading state while checking authentication
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }
  return children; // Render protected route if authenticated
};

export default AdminRoute;
