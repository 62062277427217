import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import AnimatedButton from '../components/AnimatedButton';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart, clearCart } from "../features/cartSlice";
import toast from 'react-hot-toast';
import axios from 'axios';
import yalidineData from '../components/yalidineData';

const Cart = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    
    const [deliveryType, setDeliveryType] = useState('domicile');
    const [shippingCost, setShippingCost] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState('');
    const [availableBureaux, setAvailableBureaux] = useState([]);
    const [deliveryFees, setDeliveryFees] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [apiCommunes, setApiCommunes] = useState([]);
    const selectedWilaya = watch('wilaya');
    const selectedCommuneWatch = watch('commune');

    // Memoize functions with useCallback
    const fetchDeliveryFees = useCallback(async (wilayaId) => {
        if (!wilayaId) return;
        
        setLoading(true);
        setError(null);
        
        try {
            const response = await axios.get(`https://boutiquewanted.com/api/delivery/fees/${wilayaId}`);
            setDeliveryFees(response.data);
            setApiCommunes(response.data.communes || []);
            
            if (response.data.communes?.length > 0) {
                const firstCommune = response.data.communes[0];
                setShippingCost(deliveryType === 'domicile' ? 
                    firstCommune.express_home : 
                    firstCommune.express_desk
                );
            }
        } catch (error) {
            console.error('Error fetching delivery fees:', error);
            setError('Erreur lors de la récupération des frais de livraison');
            toast.error('Erreur lors de la récupération des frais de livraison');
            setApiCommunes([]);
        } finally {
            setLoading(false);
        }
    }, [deliveryType]);

    const calculateShippingCost = useCallback((communeName, type) => {
        if (!deliveryFees) return 0;
        
        const commune = deliveryFees.communes.find(c => c.name === communeName);
        if (!commune) return 0;
        
        return type === 'domicile' ? commune.express_home : commune.express_desk;
    }, [deliveryFees]);

    const getBureaux = useCallback((wilaya, commune) => {
        if (!wilaya || !commune || !yalidineData.wilayas[wilaya]?.bureaux[commune]) return [];
        return yalidineData.wilayas[wilaya].bureaux[commune];
    }, []);

    // First useEffect for fetching delivery fees
    useEffect(() => {
        if (selectedWilaya && yalidineData.wilayas[selectedWilaya]) {
            const wilayaCode = yalidineData.wilayas[selectedWilaya].code;
            fetchDeliveryFees(wilayaCode);
        }
    }, [selectedWilaya, fetchDeliveryFees]);

    // Second useEffect for handling commune changes
    useEffect(() => {
        if (selectedWilaya && selectedCommuneWatch) {
            if (deliveryType === 'pointRelais') {
                const bureaux = getBureaux(selectedWilaya, selectedCommuneWatch);
                setAvailableBureaux(bureaux || []);
            }
            
            setSelectedCommune(selectedCommuneWatch);
            
            if (deliveryFees) {
                const newShippingCost = calculateShippingCost(selectedCommuneWatch, deliveryType);
                setShippingCost(newShippingCost);
            }
        }
    }, [
        selectedWilaya,
        selectedCommuneWatch,
        deliveryType,
        deliveryFees,
        calculateShippingCost,
        getBureaux
    ]);

    const handleDeliveryTypeChange = useCallback((event) => {
        const newType = event.target.value;
        setDeliveryType(newType);
        if (selectedCommune) {
            const newShippingCost = calculateShippingCost(selectedCommune, newType);
            setShippingCost(newShippingCost);
        }
    }, [selectedCommune, calculateShippingCost]);

    // Calculate totals
    const productTotal = cartItems.reduce((acc, item) => {
        const itemPrice = item.sold ? item.price * (1 - item.sold / 100) : item.price;
        return acc + itemPrice * item.quantity;
    }, 0);

    const finalTotal = productTotal + shippingCost;

    
    const onSubmit = async (data) => {
        try {
            if (cartItems.length === 0) {
                toast.error('Votre panier est vide');
                return;
            }

            const orderData = {
                items: cartItems.map(item => ({
                    image: item.image,
                    title: item.title,
                    quantity: item.quantity,
                    price: item.sold 
                        ? (item.price * (1 - item.sold / 100))
                        : item.price,
                    color: item.color || item.selectedColor || 'N/A',
                    size: item.size || item.selectedSize || 'N/A'
                })),
                firstname: data.prenom,
                familyname: data.nom,
                contact_phone: data.telephone,
                address: data.address,
                to_commune_name: data.commune,
                to_wilaya_name: data.wilaya,
                product_list: cartItems.map(item => `${item.title} - ${item.color || 'N/A'} - ${item.size || 'N/A'} `),
                price: productTotal,
                is_stopdesk: deliveryType === "pointRelais",
                livraison_cost: shippingCost,
                bureau: data.bureau
            };

            const response = await axios.post('https://boutiquewanted.com/api/orders', orderData);
    
            if (response.status === 201) {
                toast.success('Commande passée avec succès !');
                dispatch(clearCart());
                reset();
            }
        } catch (error) {
            console.error("Erreur détaillée:", error.response?.data || error);
            toast.error(error.response?.data?.message || 'Erreur lors de la commande');
        }
    };

    return (
<div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-12 lg:w-[82%] lg:mx-auto lg:p-4 bg-gray-200 rounded-2xl shadow-xl lg:mt-0 mt-6 mx-4 text-right">
    <div className="col-span-5 lg:p-4 p-2">
        <div className="bg-white rounded-2xl lg:mb-0 mb-16">
            
            <div className="grid lg:grid-cols-1 grid-cols-1 font-semibold pl-2 lg:pl-3 py-3 lg:pt-5 lg:pr-0 lg:pb-4">
                <div className="flex justify-between">
                    <p className="lg:pl-2 text-sm lg:text-base">المنتج</p>
                    <div className="flex lg:mr-14 mr-2">
                        <p className="lg:w-1/3 lg:pl-11 mr-3 lg:mr-12 text-sm lg:text-base">السعر</p>
                        <p className="lg:w-1/3 lg:pl-8 mr-4 lg:mr-16 text-sm lg:text-base">الكمية</p>
                        <p className='text-sm lg:text-base'>المجموع</p>
                    </div>
                </div>
            </div>

            {cartItems.map((item) => (
                <div className='lg:p-3 lg:pt-0 pb-1'>
                    <hr className='border-2 lg:w-[98%] mx-auto rounded-2xl lg:mb-3'/>  
                    <div key={item.id} className="grid grid-cols-9 font-semibold ml-1">
                        {/* Détails du produit */}
                        <div className="lg:col-span-4 col-span-5 lg:grid lg:grid-cols-4 flex flex-cols-4 p-1 lg:p-0 lg:pl-1 pt-2"> 
                            <div className="col-span-1 w-24 lg:w-full lg:h-28 h-28">
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    className="w-full h-full object-cover rounded-2xl lg:p-1"
                                />
                            </div>
                            <div className="col-span-3 flex flex-col lg:pt-2 lg:ml-1 items-start ml-1">
                                <div className='flex items-center mt-4 lg:mt-2'>
                                    <p className="flex text-xs lg:text-base font-medium">{item.title}</p>
                                </div>
                                <div className="flex items-center mt-0.5">
                                    <p className="text-gray-500 flex text-xs lg:text-base mr-0"></p>
                                    <p className="text-gray-500 flex text-xs lg:text-base">{item.color}</p>
                                </div>
                                <div className="flex items-center mt-0.5">
                                    <p className="text-gray-500 flex text-xs lg:text-base mr-1">الحجم:</p>
                                    <p className="text-gray-500 flex text-xs lg:text-base">{item.size}</p>
                                </div>
                                <div className="block lg:hidden col-span-1">
                                    <button onClick={() => {dispatch(removeFromCart(item.id)); toast.success('تمت إزالة المنتج من السلة');}}>
                                        <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Bouton Supprimer (écrans larges) */}
                        <div className="lg:flex hidden col-span-1 flex ml-auto mr-4">
                            <button onClick={() => {dispatch(removeFromCart(item.id)); toast.success('تمت إزالة المنتج من السلة');}}>
                                <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                            </button>
                        </div>

                        {/* Section Prix, Quantité et Total */}
                        <div className="col-span-4 flex items-center justify-around text-center my-auto lg:py-2">
                            <p className="lg:w-1/3">{item.sold ? (item.price * (1 - item.sold / 100)) : item.price}</p>
                            <div className="lg:w-1/3 flex flex-col lg:flex-row items-center justify-center space-y-2 lg:space-y-0 lg:pl-6 font-semibold">
                                <button onClick={() => dispatch(incrementQuantity(item.id))}>
                                    <FontAwesomeIcon icon={faPlus} className="text-gray-500 lg:border-2 border-gray-500 mx-2 rounded-md px-1 py-1 text-xs" />
                                </button>
                                <p className="text-center lg:mx-1">{item.quantity}</p>
                                <button onClick={() => dispatch(decrementQuantity(item.id))}>
                                    <FontAwesomeIcon icon={faMinus} className="text-gray-500 lg:border-2 border-gray-500 mx-2 rounded-md px-1 py-1 text-xs" />
                                </button>
                            </div>
                            <p className="lg:w-1/3 text-gray-600">{item.sold ? (((item.price * (1 - item.sold / 100))) * item.quantity) : (item.price * item.quantity)}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>                
    </div>

    <div className="lg:col-span-2 bg-white p-4 rounded-2xl mx-2 mt-12 lg:mx-0 lg:mt-0 mb-2">
        <p className='p-1 pt-2 text-lg font-semibold'>تأكيد الطلب</p>
            
        <form onSubmit={handleSubmit(onSubmit)} className="flex-col lg:mt-4">
            {/* Nom & Prénom */}
            <div className="flex gap-4 mb-4">
                <label className="flex-1">
                    <input
                        {...register("nom", {
                            required: "الاسم العائلي مطلوب",
                            pattern: {
                                value: /^[A-Za-zء-ي\s]+$/,
                                message: "الاسم العائلي يجب أن يحتوي على حروف فقط"
                            }
                        })}
                        className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                        placeholder="الاسم العائلي"
                    />
                    {errors.nom && <span className="text-red-500 text-sm">{errors.nom.message}</span>}
                </label>

                <label className="flex-1">
                    <input
                        {...register("prenom", {
                            required: "الاسم الشخصي مطلوب",
                            pattern: {
                                value: /^[A-Za-zء-ي\s]+$/,
                                message: "الاسم الشخصي يجب أن يحتوي على حروف فقط"
                            }
                        })}
                        className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                        placeholder="الاسم الشخصي"
                    />
                    {errors.prenom && <span className="text-red-500 text-sm">{errors.prenom.message}</span>}
                </label>
            </div>

            {/* Téléphone */}
            <label className="block mb-4">
                <input
                    {...register("telephone", {
                        required: "رقم الهاتف مطلوب",
                        pattern: {
                            value: /^(07\d{8}|06\d{8}|05\d{8}|043\d{7})$/,
                            message: "رقم الهاتف غير صالح"
                        }
                    })}
                    className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                    placeholder="رقم الهاتف"
                />
                {errors.telephone && <span className="text-red-500 text-sm">{errors.telephone.message}</span>}
            </label>

            {/* Type de livraison */}
            <label className="block mb-4">
                <select 
                    value={deliveryType}
                    onChange={handleDeliveryTypeChange}
                    className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                >
                    <option value="domicile">توصيل إلى المنزل</option>
                    <option value="pointRelais">نقطة الاستلام</option>
                </select>
            </label>

             {/* Sélection de Wilaya */}
            {deliveryType && (
             <label className="block mb-4">
                <select 
                    {...register("wilaya", { required: "الولاية مطلوبة" })}
                    className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                >
                    <option value="">اختر الولاية</option>
                    {Object.entries(yalidineData.wilayas).map(([wilayaName, wilayaData]) => (
                        <option key={wilayaData.code} value={wilayaName}>
                            {`${wilayaData.code}. ${wilayaName}`}
                        </option>
                    ))}
                </select>
                {errors.wilaya && <span className="text-red-500 text-sm">{errors.wilaya.message}</span>}
            </label>
            )}

            {/* Sélection de Commune */}
            {selectedWilaya && (
                <label className="block mb-4">
                    <select
                        {...register("commune", { required: "البلدية مطلوبة" })}
                        className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                    >
                        <option value="">اختر البلدية</option>
                        {deliveryType === 'domicile' 
                            ? apiCommunes.map((commune) => (
                                <option key={commune.name} value={commune.name}>
                                    {commune.name}
                                </option>
                              ))
                            : Object.keys(yalidineData.wilayas[selectedWilaya]?.bureaux || {}).map((commune) => (
                                <option key={commune} value={commune}>
                                    {commune}
                                </option>
                              ))
                        }
                    </select>
                    {errors.commune && <span className="text-red-500 text-sm">{errors.commune.message}</span>}
                    {loading && <span className="text-blue-500 text-sm">جارٍ تحميل البلديات...</span>}
                </label>
            )}

            {/* Sélection de Bureau */}
            {deliveryType === 'pointRelais' && selectedCommune && (
                <label className="block mb-4">
                    <select
                        {...register("bureau", { required: "المكتب مطلوب" })}
                        className="bg-gray-200 w-full rounded-2xl pl-3 py-2"
                    >
                        <option value="">اختر المكتب</option>
                        {availableBureaux.map((bureau) => (
                            <option key={bureau.id} value={bureau.id}>
                                {bureau.nom} - {bureau.adresse}
                            </option>
                        ))}
                    </select>
                    {errors.bureau && <span className="text-red-500 text-sm">{errors.bureau.message}</span>}
                </label>
            )}

            {/* Adresse (livraison à domicile) */}
            <label className="block mb-4">
                <textarea
                    {...register("address", {
                        required: "العنوان مطلوب",
                        minLength: {
                            value: 10,
                            message: "يجب أن يحتوي العنوان على 10 أحرف على الأقل"
                        }
                    })}
                    className="bg-gray-200 w-full rounded-2xl pl-3 py-2 min-h-[100px] resize-y"
                    placeholder="العنوان الكامل (الشارع، الرقم، إلخ)"
                />
                {errors.address && <span className="text-red-500 text-sm">{errors.address.message}</span>}
            </label>
            
            {/* Résumé des Coûts */}
            <div className="bg-custom-light-yellow p-4 rounded-2xl mt-4">
                <p className="text-lg font-bold mb-2">
                    المجموع الفرعي <span className="float-left">{productTotal.toFixed(1)} دج</span>
                </p>
                <p className="text-lg font-bold mb-2">
                    تكاليف الشحن <span className="float-left">{shippingCost} دج</span>
                </p>
                <hr className="my-2 border-gray-400" />
                <p className="text-lg font-bold">
                    المجموع المستحق <span className="float-left">{finalTotal.toFixed(1)} دج</span>
                </p>
                <AnimatedButton className="w-full mt-5 p-3">
                    <input type="submit" value="تأكيد" className="hover:cursor-pointer w-full" />
                </AnimatedButton>
                {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
            </div>
        </form>
    </div>
</div>
);
};

export default Cart;