import React, { useState, useEffect } from 'react';
import { FaAngleRight, FaAngleLeft, FaSearch } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CollectionCard from '../components/CollectionCard';
// import AnimatedButton from '../components/AnimatedButton';
import axios from 'axios';

const ShopPage = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  
  // Menu state
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [subCategoryAnchorEl, setSubCategoryAnchorEl] = useState(null);
  
  const itemsPerPage = 15;

  const subCategories = {
    'Tous les produits': [],
    'Vestes et Manteaux': ['Parka', 'Veste lègère', 'Manteau', 'Veste'],
    'Hauts': ['Chemise', 'Pull', 'Half Zipper', 'Sweat-Shirt', 'T-shirt'],
    'Jeans et Pantalons': [], // 'Jeans', 'Cargo', 'Pantalon', 'Short'
    'Chaussures': ['Bottes', 'Espadrine', 'Sandales', 'Mocassin', 'Chaussure'],
    'Ensemble': [],
    'Nouveau': [],
    'Sold': []
  };

  // Gestionnaire de recherche
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Réinitialiser la page lors d'une nouvelle recherche
    setIsSearching(e.target.value.length > 0);
  };

  


  // Menu handlers
  const handleCategoryClick = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleSubCategoryClick = (event) => {
    setSubCategoryAnchorEl(event.currentTarget);
  };

  const handleCategoryClose = (selectedCategory) => {
    setCategoryAnchorEl(null);
    if (typeof selectedCategory === 'string') {
      // If "Tous les produits" is selected, reset both category and subcategory
      if (selectedCategory === 'Tous les produits') {
        setCategory('');
        setSubCategory('');
      } else {
        const newCategory = selectedCategory === category ? '' : selectedCategory;
        setCategory(newCategory);
        setSubCategory('');
      }
    }
  };

  const handleSubCategoryClose = (selectedSubCategory) => {
    setSubCategoryAnchorEl(null);
    if (typeof selectedSubCategory === 'string') {
      setSubCategory(selectedSubCategory === subCategory ? '' : selectedSubCategory);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://boutiquewanted.com/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des produits:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [category, subCategory]);

  // Fonction de filtrage mise à jour pour inclure la recherche
  const filteredProducts = products.filter((product) => {
    // Filtrage par recherche
    const searchMatch = searchQuery
      ? product.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.marque?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description?.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    // Filtrage par catégorie
    const matchesCategory = !category ||
      (category === 'Nouveau' && product.isNew) ||
      (category === 'Sold' && product.discount > 0) ||
      product.category === category;

    // Filtrage par sous-catégorie
    const matchesSubCategory = !subCategory || product.subCategory === subCategory;

    return searchMatch && matchesCategory && matchesSubCategory;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get display text for category button
  const getCategoryButtonText = () => {
    if (!category) {
      return 'Tous les produits';
    }
    return category;
  };

  return (
    <div className="container mx-auto mt-6 lg:px-6">
      
      {/* Barre de recherche */}
      <div className="flex justify-center lg:my-6 px-8 lg:px-24">
    <div className="relative w-full max-w-xl ">
        <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="ابحث عن منتج..."
            className="w-full px-4 py-2 pl-10 pr-8 border-2 border-black rounded-lg focus:outline-none focus:border-yellow-500 "
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        {searchQuery && (
            <button
                onClick={() => {
                    setSearchQuery('');
                    setIsSearching(false);
                }}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-black"
            >
                ×
            </button>
        )}
    </div>
</div>

{/* عرض نتائج البحث */}
{isSearching && (
    <div className="text-center mb-2">
        {filteredProducts.length > 0 ? (
            <p className="text-gray-600">
                "{searchQuery}" تم العثور على {filteredProducts.length} نتيجة/نتائج لـ 
            </p>
        ) : (
            <p className="text-gray-600">
                "{searchQuery}" لم يتم العثور على نتائج لـ 
            </p>
        )}
    </div>
)}




      {/* Category and Subcategory Menus */}
      <div className="flex flex-col space-y-4 lg:my-6 my-3 px-6 lg:px-24">
        <div className="flex gap-4 lg:ml-0 ml-2">
        <Button
          variant="contained"
          onClick={handleCategoryClick}
          sx={{
            backgroundColor: 'black',
            '&:hover': {
              backgroundImage: 'linear-gradient(to right, #facc15, #b45309)',
            },
          }}
        >
          {getCategoryButtonText()}
        </Button>
          <Menu
            anchorEl={categoryAnchorEl}
            open={Boolean(categoryAnchorEl)}
            onClose={() => handleCategoryClose()}
          >
            {Object.keys(subCategories).map((type) => (
              <MenuItem 
                key={type}
                onClick={() => handleCategoryClose(type)}
                selected={category === type}
              >
                {type}
              </MenuItem>
            ))}
          </Menu>

          {category && subCategories[category]?.length > 0 && !['Nouveau', 'Sold'].includes(category) && (
            <>
              <Button
                variant="contained"
                onClick={handleSubCategoryClick}
                sx={{
                  backgroundColor: 'black',
                  '&:hover': {
                    backgroundImage: 'linear-gradient(to right, #facc15, #b45309)',
                  },
                }}
              >
                {subCategory || 'Filtre'}
              </Button>
              
              <Menu
                anchorEl={subCategoryAnchorEl}
                open={Boolean(subCategoryAnchorEl)}
                onClose={() => handleSubCategoryClose()}
              >
                {subCategories[category].map((subType) => (
                  <MenuItem
                    key={subType}
                    onClick={() => handleSubCategoryClose(subType)}
                    selected={subCategory === subType}
                  >
                    {subType}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </div>
      </div>

      {/* Collections Display */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-6 lg:gap-y-12 gap-x-0 lg:pl-24 lg:pr-12 mx-5 lg:mx-0">
        {currentItems.map((product) => (
          <CollectionCard key={product._id} collection={product} />
        ))}
      </div>

      {/* Pagination */}
      {filteredProducts.length > itemsPerPage && (
      <div className="flex justify-center mt-6 lg:mt-10 lg:space-x-3 space-x-2">
        <button
          className={`px-3 py-1 rounded-md ${currentPage === 1 ? 'bg-black text-white cursor-not-allowed' : 'bg-black text-white hover:bg-gradient-to-r hover:from-yellow-400 hover:to-yellow-700 hover:text-black'}`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaAngleLeft />
        </button>

        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
          <button
            key={pageNumber}
            className={`px-3 py-1 rounded-md ${
              currentPage === pageNumber ? 'bg-white text-black border-2 border-yellow-500' : 'bg-black text-white'
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}

        <button
          className={`px-3 py-1 rounded-md ${currentPage === totalPages ? 'bg-black text-white cursor-not-allowed' : 'bg-black text-white hover:bg-gradient-to-r hover:from-yellow-400 hover:to-yellow-700 hover:text-black'}`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaAngleRight />
        </button>
      </div>
      )}


<div class="bg-gradient-to-r from-yellow-400 to-yellow-700 py-8 mt-24 px-6">
    <h2 class="text-4xl text-white font-bold text-center mb-8">خدماتنا</h2>
    <div class="grid md:grid-cols-3 gap-6 mb-12">
        <div class="bg-white shadow-lg rounded-lg p-6 text-center">
            <div class="text-5xl text-yellow-500 mb-4">🔄</div>
            <h3 class="text-xl font-semibold mb-2 text-black">إمكانية التغيير</h3>
            <p class="text-gray-700">نضمن لك إمكانية تبديل المنتجات بسهولة.</p>
        </div>
        <div class="bg-white shadow-lg rounded-lg p-6 text-center">
            <div class="text-5xl text-yellow-600 mb-4">💳</div>
            <h3 class="text-xl font-semibold mb-2 text-black">الدفع عند الاستلام</h3>
            <p class="text-gray-700">ادفع فقط عند استلام المنتج.</p>
        </div>
        <div class="bg-white shadow-lg rounded-lg p-6 text-center">
            <div class="text-5xl text-yellow-700 mb-4">🚚</div>
            <h3 class="text-xl font-semibold mb-2 text-black">التوصيل متوفر</h3>
            <p class="text-gray-700">نوفر التوصيل إلى جميع الولايات الجزائرية.</p>
        </div>
    </div>

    <div class="bg-white rounded-lg shadow-lg p-6">
        <h3 class="text-2xl font-semibold text-black mb-3 text-center">Nos Réseaux Sociaux</h3>
        <hr class="border-gray-400 mb-3 mx-auto w-1/2"/>
        <div class="flex justify-center space-x-6 mb-6">
            <a href="https://www.facebook.com/wantedtlemcen" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <i class="fab fa-facebook text-yellow-600 hover:text-yellow-500 text-3xl"></i>
            </a>
            <a href="https://www.instagram.com/boutique__wanted/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <i class="fab fa-instagram text-yellow-600 hover:text-yellow-500 text-3xl"></i>
            </a>
            <a href="https://www.tiktok.com/@boutiquewanted/" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                <i class="fab fa-tiktok text-yellow-600 hover:text-yellow-500 text-3xl"></i>
            </a>
        </div>

        <div className="my-8">
          <h3 className="text-xl font-semibold text-center mb-4">Localisation de la Boutique</h3>
          <iframe
              className="w-full h-64 rounded-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81277.31029574768!2d-1.3952790970030222!3d34.88657572673385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd78c9c91d381abf%3A0x78bf4fa8dd696db!2sBoutique%20WANTED!5e0!3m2!1sen!2sdz!4v1732631268837!5m2!1sen!2sdz"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
          </iframe>
      </div>

    </div>
</div>

    </div>
  );
};

export default ShopPage;