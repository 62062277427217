import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Camera } from 'lucide-react';
import imageCompression from 'browser-image-compression';


const subCategories = {
  'Vestes et Manteaux': ['Parka', 'Veste lègère', 'Manteau', 'Veste'],    
  'Hauts': ['Chemise', 'Pull', 'Half Zipper', 'Sweat-Shirt', 'T-shirt'],
  'Jeans et Pantalons': [], // 'Jeans', 'Cargo', 'Pantalon', 'Short'
  'Chaussures': ['Bottes', 'Espadrine', 'Sandales', 'Mocassin', 'Chaussure'],
  'Ensemble': [],
};
/*
'Vestes et Manteaux': ['Jacket', 'Manteau'],
  'Vêtements du Haut': ['Surchemise', 'Pull', 'Half Zipper', 'Hoodies', 'T-shirt'],
  'Jeans et Pantalons': ['Jeans', 'Cargo', 'Pantalon', 'Short'],
  'Collection': ['Ensemble Oversize'],
  'Chaussures': ['Bottes', 'Espadrine'],
*/

const colorOptions = [
  // Blancs
  'blanc', 'blanc cassé', 'blanc crème', 'écru', 'ivoire',
  // Noirs
  'noir', 'noir charbon', 'noir intense',
  // Gris
  'gris', 'gris clair', 'gris souris', 'gris anthracite', 'gris perle', 'gris chiné', 'argent',
  // Bleus
  'bleu', 'bleu marine', 'bleu ciel', 'bleu royal', 'bleu nuit', 'bleu pétrole', 'bleu glacier', 'bleu indigo', 'turquoise',
  // Verts
  'vert', 'vert bouteille', 'vert militaire', 'vert sapin', 'vert olive', 'vert émeraude', 'vert pistache', 'vert menthe', 'vert kaki', 'vert sauge',
  // Marrons
  'marron', 'marron glacé', 'marron chocolat', 'camel', 'cognac', 'taupe', 'café', 'noisette',
  // Beiges
  'beige', 'beige clair', 'beige foncé', 'sable', 'chamois',
  // Rouges
  'rouge', 'bordeaux', 'rouge carmin', 'rouge brique', 'rouge cerise', 'corail',
  // Roses
  'rose', 'rose pâle', 'rose poudré', 'rose fuchsia', 'rose saumon',
  // Violets
  'violet', 'violet foncé', 'aubergine', 'prune', 'mauve', 'lilas',
  // Jaunes
  'jaune', 'jaune pâle', 'jaune moutarde', 'jaune safran', 'or',
  // Oranges
  'orange', 'orange brûlé', 'mandarine', 'abricot', 'pêche',
];
const sizeOptions = ['2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];
const sizeChaussure = ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45'];

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState({
    title: '',
    marque: '',
    images: [],
    price: 0,
    category: '',
    subCategory: '',
    description: '',
    colors: [],
    sizes: [],
    isNew: false,
    // enStock: true,
    discount: 0
  });
  const [error, setError] = useState(null);

  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);

  const compressionOptions = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1200,
    useWebWorker: true,
    initialQuality: 1,
  };

  // Charger les données du produit
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://boutiquewanted.com/api/products/${id}`);
        const product = response.data;
        setProductData(product);
        // Initialiser les previews avec les URLs des images existantes
        setImagesPreviews(product.images);
      } catch (error) {
        console.error('Erreur de chargement du produit :', error);
        setError('Erreur lors du chargement du produit.');
      }
    };
    fetchProduct();
  }, [id]);

  async function compressImage(file) {
    try {
      if (!file || !file.type.startsWith('image/')) {
        throw new Error('Le fichier doit être une image');
      }
      
      const compressedFile = await imageCompression(file, compressionOptions);
      return compressedFile;
    } catch (error) {
      console.error('Erreur lors de la compression:', error);
      throw error;
    }
  }

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setIsCompressing(true);
        setError(null);
  
        if (!file.type.startsWith('image/')) {
          throw new Error('Sélectionnez un fichier image');
        }
  
        if (file.size > 10 * 1024 * 1024) {
          throw new Error('Fichier trop volumineux (< 10MB)');
        }
  
        const compressedFile = await compressImage(file);
        
        const formData = new FormData();
        formData.append('image', compressedFile);
        
        const response = await axios.post('https://boutiquewanted.com/api/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
  
        const imageUrl = response.data.imageUrl;
  
        // Mettre à jour les images
        const updatedImages = [...productData.images];
        updatedImages[index] = imageUrl;
        setProductData(prev => ({
          ...prev,
          images: updatedImages
        }));
  
        // Créer preview
        const reader = new FileReader();
        reader.onload = (event) => {
          const newPreviews = [...imagesPreviews];
          newPreviews[index] = event.target.result;
          setImagesPreviews(newPreviews);
        };
        reader.readAsDataURL(compressedFile);
  
      } catch (error) {
        console.error('Erreur de traitement de l\'image:', error);
        setError(error.message || 'Erreur de traitement');
      } finally {
        setIsCompressing(false);
      }
    }
  };
  
  const handleAddImageField = () => {
    setProductData(prev => ({
      ...prev,
      images: [...prev.images, '']
    }));
    setImagesPreviews(prev => [...prev, '']);
  };
/*
  const removeImage  = async (index) => {
    try {
      const imageToRemove = productData.images[index];
      
      if (typeof imageToRemove === 'string') {
        const filename = imageToRemove.split('/').pop();
        await axios.delete(`https://boutiquewanted.com/api/upload/${filename}`);
      }

      const updatedImages = productData.images.filter((_, i) => i !== index);
      const updatedPreviews = imagesPreviews.filter((_, i) => i !== index);

      setProductData(prev => ({
        ...prev,
        images: updatedImages
      }));
      setImagesPreviews(updatedPreviews);

    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
      setError('Erreur lors de la suppression de l\'image.');
    }
  };
  */

  const removeImage = async (index) => {
    try {
      const imageToRemove = productData.images[index];
      
      // Si c'est une URL Cloudinary, faites un appel backend pour supprimer
      if (typeof imageToRemove === 'string' && imageToRemove.includes('cloudinary')) {
        const publicId = imageToRemove.split('/').pop().split('.')[0];
        await axios.delete(`https://boutiquewanted.com/api/upload/${publicId}`);
      }
  
      const updatedImages = productData.images.filter((_, i) => i !== index);
      const updatedPreviews = imagesPreviews.filter((_, i) => i !== index);
  
      setProductData(prev => ({
        ...prev,
        images: updatedImages
      }));
      setImagesPreviews(updatedPreviews);
  
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
      setError('Erreur lors de la suppression de l\'image.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      if (!productData.title || !productData.price || !productData.category) {
        throw new Error('Veuillez remplir tous les champs requis');
      }

      const imageUrls = await Promise.all(
        productData.images.filter(Boolean).map(async (image) => {
          if (image instanceof File) {
            const formData = new FormData();
            formData.append('image', image);
            
            const response = await axios.post('https://boutiquewanted.com/api/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            });
            
            return response.data.imageUrl;
          }
          return image; // Garder l'URL existante si ce n'est pas un nouveau fichier
        })
      );

      const finalProductData = {
        ...productData,
        images: imageUrls.filter(Boolean)
      };

      await axios.put(`https://boutiquewanted.com/api/products/${id}`, finalProductData);
      navigate('/adminProduct');
    } catch (error) {
      setError(error.message || 'Erreur lors de la mise à jour du produit');
      console.error('Submit error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePrice = (e) => {
    const value = e.target.value;
    setProductData((prevData) => ({
      ...prevData,
      price: parseFloat(value) || 0, // Ensure price is a number, defaulting to 0 if empty
    }));
  };

  const handleCheckboxChange = (e, type) => {
    const { value, checked } = e.target;
    setProductData(prev => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter(item => item !== value),
    }));
  };

  return (
    <div className="edit-product my-8 mb-24 px-4 sm:mx-32">
      <h1 className="text-3xl font-semibold bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent mb-8">Modifier le produit</h1>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="title" className="block text-lg">Titre du produit</label>
          <input
            type="text"
            id="title"
            name="title"
            value={productData.title}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="marque" className="block text-lg">Marque</label>
          <input
            type="text"
            id="marque"
            name="marque"
            value={productData.marque}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="price" className="block text-lg">Prix</label>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price}
            onChange={handleChangePrice}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="category" className="block text-lg">Catégorie</label>
          <select
            id="category"
            name="category"
            value={productData.category}
            onChange={(e) => {
              handleChange(e);
              setProductData({
                ...productData,
                subCategory: '' // Réinitialiser la sous-catégorie lorsqu'on change de catégorie
              });
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Sélectionnez une catégorie</option>
            {Object.keys(subCategories).map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="subCategory" className="block text-lg">Sous-catégorie</label>
          <select
            id="subCategory"
            name="subCategory"
            value={productData.subCategory}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
            disabled={!productData.category} // Désactiver si aucune catégorie n'est sélectionnée
          >
            <option value="">Sélectionnez une sous-catégorie</option>
            {subCategories[productData.category]?.map((subCat) => (
              <option key={subCat} value={subCat}>{subCat}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="description" className="block text-lg">Description</label>
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            
          />
        </div>
        {/* Images 
        <div className="space-y-4">
          <label htmlFor="images" className="block text-lg font-medium">
            Images
          </label>
          
          {previews.map((preview, index) => (
            <div key={index} className="relative border p-4 rounded-lg">
              {preview && (
                <div className="mb-2">
                  <img 
                    src={preview} 
                    alt={`Preview ${index + 1}`} 
                    className="h-32 object-cover rounded-md"
                  />
                </div>
              )}
              
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e, index)}
                  accept="image/*"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={handleAddImageField}
            className="mt-3 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md"
          >
            Ajouter une image
          </button>
        </div>
        */}

        {/* Section upload d'images mise à jour */}
        <div className="mb-6">
          <label className="block text-lg mb-4 font-medium">Images du produit</label>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {productData.images.map((_, index) => (
              <div key={index} className="relative">
                <div className="flex flex-col items-center border-2 border-dashed border-gray-300 rounded-lg p-4">
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(e, index)}
                    accept="image/*"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    disabled={isCompressing}
                    aria-label="Sélectionner une image"
                  />
                  
                  {imagesPreviews[index] ? (
                    <div className="relative w-full">
                      <img 
                        src={imagesPreviews[index]} 
                        alt={`Aperçu ${index + 1}`} 
                        className="w-full h-48 object-cover rounded-lg"
                      />
                      <button
                        onClick={() => removeImage(index)}
                        className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
                        type="button"
                        disabled={isCompressing}
                      >
                        ✕
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-8">
                      {isCompressing ? (
                        <div className="text-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2"></div>
                          <p className="text-sm text-gray-500">Compression en cours...</p>
                        </div>
                      ) : (
                        <>
                          <Camera className="w-12 h-12 text-gray-400 mb-2" />
                          <p className="text-sm text-gray-500">
                            Appuyez pour ajouter une photo
                          </p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {productData.images.length < 8 && (
            <button
              type="button"
              onClick={handleAddImageField}
              className="w-full mt-3 bg-blue-500 text-white p-3 rounded-lg font-medium hover:bg-blue-600 transition-colors"
              disabled={isCompressing}
            >
              Ajouter une image
            </button>
          )}
        </div>

        <div>
          <label className="block text-lg">Couleurs</label>
          <div className="flex flex-wrap">
            {colorOptions.map((color) => (
              <label key={color} className="p-2 flex">
                <input
                  type="checkbox"
                  value={color}
                  checked={productData.colors.includes(color)}
                  onChange={(e) => handleCheckboxChange(e, 'colors')}
                />
                <p className='ml-1'>{color}</p>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-lg">Tailles</label>
          <div className="flex flex-wrap">
            {productData.category === 'Chaussures'
              ? sizeChaussure.map((size) => (
                  <label key={size} className="p-2 flex">
                    <input
                      type="checkbox"
                      value={size}
                      checked={productData.sizes.includes(size)}
                      onChange={(e) => handleCheckboxChange(e, 'sizes')}
                    />
                    <p className='ml-1'>{size}</p>
                  </label>
                ))
              : sizeOptions.map((size) => (
                  <label key={size} className="p-2 flex">
                    <input
                      type="checkbox"
                      value={size}
                      checked={productData.sizes.includes(size)}
                      onChange={(e) => handleCheckboxChange(e, 'sizes')}
                    />
                    <p className='ml-1'>{size}</p>
                  </label>
                ))}
          </div>
        </div>

        <div>
          <label htmlFor="isNew" className="inline-flex items-center">
            <input
              type="checkbox"
              id="isNew"
              name="isNew"
              checked={productData.isNew}
              onChange={(e) => setProductData({ ...productData, isNew: e.target.checked })}
              className="mr-2"
            />
            <span className="text-lg">Produit neuf</span>
          </label>
        </div>
        <div>
          <label htmlFor="discount" className="block text-lg">Remise (%)</label>
          <input
            type="number"
            id="discount"
            name="discount"
            value={productData.discount}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        
        <button
          type="submit"
          className="bg-blue-500 text-white p-3 rounded-md shadow-md hover:bg-blue-600 transition-colors"
          disabled={isCompressing}
        >
          Modifier le produit
        </button>
      </form>
    </div>
  );
};

export default EditProduct;