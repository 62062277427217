// src/components/CollectionCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addItemToCart } from '../features/cartSlice';
import { toast } from 'react-hot-toast';

const CollectionCard = ({ collection }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { images = [], title = 'Produit', marque = '', price = 0, colors, sizes, isNew, discount = 0 } = collection; // enStock
    
  const handleAddToCart = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevents click from propagating

    /* if (!collection.enStock) {
      toast.error('Ce produit est en rupture');
    } */

    const defaultCartItem = {
      id: collection._id,
      title: collection.title,
      price: collection.price,
      image: collection.images[0],
      color: collection.colors[0],
      size: collection.sizes[0],
      sold: collection.discount,
      quantity: 1,
    };

    dispatch(addItemToCart(defaultCartItem));
    // if (collection.enStock) 
    toast.success('تم إضافة المنتج إلى السلة!');
  };

  // Modified version of the handleCardClick
  const handleCardClick = () => {
    if (collection && collection._id) {
      // Remove the state passing since we'll fetch the data in the product page
      navigate(`/produit/${collection._id}`);
    } else {
      console.error("Les données de collection sont incomplètes.");
    }
  };

  return (
    <div onClick={handleCardClick} className="block lg:w-56 cursor-pointer">
      <div className="bg-white rounded-xl overflow-hidden lg:h-[382px] flex flex-col justify-between lg:mx-0 mr-3 ml-3">
        <div className="relative lg:w-full h-48 lg:h-64 ">
          <img
            src={collection.images?.[0]}
            alt={collection.title}
            className="w-full h-full object-cover"
          />
          {collection.isNew && (
            <span className="absolute top-1 lg:top-2 lg:left-2 left-1 bg-black text-white lg:px-2 px-1 py-0.5 lg:py-1 rounded-full text-xs">
              New
            </span>
          )}
          {collection.discount !== 0 && (
            <span className="absolute top-1 lg:top-2 lg:right-2 right-1 bg-black text-white lg:px-2 px-1 py-0.5 lg:py-1 rounded-full text-xs">
              -{collection.discount}%
            </span>
          )}
        </div>
        <div className="lg:p-2 p-1 text-center">
          <h3 className="lg:text-md text-sm font-semibold truncate lg:h-6 overflow-hidden">
            {collection.title}
          </h3>
          <p className="text-gray-500 lg:text-sm text-sm">{collection.marque}</p>
          {collection.discount !== 0 && (
            <div className="lg:text-lg text-md font-semibold">{collection.price * (1 - collection.discount / 100)} DA <span className='text-gray-500 line-through'>{collection.price} DA</span></div>
          )}
          {collection.discount === 0 && (
            <div className="lg:text-lg text-md font-semibold">{collection.price} DA</div>
          )}
          <button 
            className="bg-black text-white lg:mt-2 xs:mt-1 lg:px-4 py-1 rounded-full w-[80%] hover:bg-gradient-to-r hover:from-yellow-400 hover:to-yellow-700 hover:text-black hover:font-bold"
            onClick={handleAddToCart}
          >
            أضف إلى السلة
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionCard;
