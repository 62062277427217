import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { addItemToCart } from '../features/cartSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';

const ProductPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the product ID from URL
  
  const [productData, setProductData] = useState(location.state?.productData || null);
  const [loading, setLoading] = useState(!location.state?.productData);
  const [error, setError] = useState(null);
  
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [mainImage, setMainImage] = useState('');

  // Fetch product data if not available from navigation state
  useEffect(() => {
    const fetchProductData = async () => {
      if (!productData && id) {
        try {
          setLoading(true);
          const response = await axios.get(`https://boutiquewanted.com/api/products/${id}`);
          setProductData(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching product:', error);
          setError('Product not found');
          setLoading(false);
        }
      }
    };

    fetchProductData();
  }, [id, productData]);

  // Initialize default values when product data is available
  useEffect(() => {
    if (productData) {
      if (productData.colors?.length > 0) {
        setSelectedColor(productData.colors[0]);
      }
      if (productData.sizes?.length > 0) {
        setSelectedSize(productData.sizes[0]);
      }
      if (productData.images?.length > 0) {
        setMainImage(productData.images[0]);
      }
    }
  }, [productData]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error || !productData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl text-gray-600">Produit non trouvé.</div>
      </div>
    );
  }

  const handleAddToCart = () => {
    const item = {
      id: productData._id,
      image: mainImage,
      title: productData.title,
      color: selectedColor,
      size: selectedSize,
      price: productData.price,
      sold: productData.discount || 0,
      quantity: quantity,
    };

    dispatch(addItemToCart(item));
    toast.success('تم إضافة المنتج إلى السلة!');

    navigate('/cart');
  };

  return (
    <div className="container mx-auto px-4 lg:px-32">
      <div className="lg:grid lg:grid-cols-5 lg:h-[80vh] lg:gap-8 bg-white rounded-lg lg:p-6 mt-4 shadow-2xl bg-purple-100">
        <div className="lg:col-span-3 flex lg:gap-0 lg:pl-0 lg:pt-0 pl-6 pt-4">
          <div className="w-1/5 lg:space-y-4 space-y-2 overflow-y-auto h-[25vh] pr-1 lg:h-[59vh] lg:mx-4 lg:p-2 mt-3 lg:mt-16" style={{ maxHeight: '500px' }}>
            {productData?.images?.map((img, index) => (
              <button
                key={index}
                className="w-full aspect-square rounded-xl overflow-hidden"
                onClick={() => setMainImage(img)}
              >
                <img
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-full object-cover"
                />
              </button>
            ))}
          </div>
          <div className="w-4/5 flex items-center justify-center">
            <img
              src={mainImage || productData.images[0]}
              alt="Main product"
              className="max-w-[75%] max-h-[500px] object-contain rounded-xl"
            />
          </div>
        </div>

        <div className="lg:col-span-2 lg:space-y-2 space-y-2 overflow-y-auto lg:p-0 lg:pr-4 lg:pt-12 p-6 mt-8 lg:mt-0">
          <h1 className="text-2xl font-bold text-gray-900">
            {productData.title}
          </h1>
          
          <div className="text-2xl font-bold text-gray-900">
            {productData.discount > 0 ? (
              <p>
                {productData.price * (1 - productData.discount / 100)} DA{' '}
                <span className='text-gray-500 line-through'>{productData.price} DA</span>
              </p>
            ) : (
              <p>{productData.price} DA</p>
            )}
            
            {productData.discount > 0 && (
              <span className="font-bold text-xs float-right text-white bg-black rounded-2xl lg:p-2 p-1">
                <p>{productData.discount}%</p>
              </span>
            )}
          </div>

          <p className="text-gray-600 text-sm">
            {productData.description}
          </p>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Couleur 
              </label>
              <select
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {productData.colors.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Taille 
              </label>
              <select
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {productData.sizes.map((size) => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Quantité 
              </label>
              <input
                type="number"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                className="mt-1 block w-24 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex space-x-4 pt-4">
            <button 
              onClick={handleAddToCart} 
              className="bg-black text-white mx-auto px-4 py-3 rounded-full w-[80%] hover:bg-gradient-to-r hover:from-yellow-400 hover:to-yellow-700 hover:text-black hover:font-bold"
            >
              أضف إلى السلة
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;