  // src/App.js
  import React from 'react';
  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
  import './App.css';
  import ShopPage from './pages/ShopPage';
  import HomeAdmin from './pages/admin/AdminHome';
  import ProductAdmin from './pages/admin/ProductAdmin';
  import OrderAdmin from './pages/admin/OrderAdmin';
  import ProductPage from './pages/ProductPage';
  import Cart from './pages/Cart';
  import { Toaster } from 'react-hot-toast';
  import AddProduct from './components/admin/AddProduct';
  import EditProduct from './components/admin/EditProduct';
  import MainLayout from './components/MainLayout';
  import AdminLayout from './components/AdminLayout';
  import ScrollToTop from './components/ScrollToTop';

  import AdminRoute from './components/admin/AdminRoute';
  import Login from './pages/admin/Login';

  function App() {
    return (
      <Router>
        <ScrollToTop />
        <Toaster position="center-top" reverseOrder={false} />
        <MainLayout>
          <AdminLayout>
          <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/admin" element={<AdminRoute><HomeAdmin /></AdminRoute>} />
              <Route path="/adminProduct" element={<AdminRoute><ProductAdmin /></AdminRoute>} />
              <Route path="/adminProduct/add" element={<AdminRoute><AddProduct /></AdminRoute>} />
              <Route path="/adminProduct/edit/:id" element={<AdminRoute><EditProduct /></AdminRoute>} />
              <Route path="/adminOrder" element={<AdminRoute><OrderAdmin /></AdminRoute>} />

              <Route path="/" element={<ShopPage />} />
              <Route path="/produit/:id" element={<ProductPage />} />
              <Route path="/cart" element={<Cart />} />
            </Routes>
          </AdminLayout>
        </MainLayout>
      </Router>
    );
  }

  export default App;
