import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AnimatedButton from '../../components/AnimatedButton';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(
        'https://boutiquewanted.com/api/authRoutes/login',
        { username, password },
        { withCredentials: true }
      );

      alert(response.data.message); // Success message
      navigate('/admin'); // Directly navigate after login, no need to check for token manually
    } catch (err) {
      setError(err.response?.data?.message || 'Erreur de connexion');
    }
  };

  return (
    <div className='my-8 mb-24 px-4 sm:mx-32'>
      <h2 className='text-3xl font-bold'>Connexion Admin</h2>
      <form onSubmit={handleLogin}  className='mt-4 grid gap-4'>
        <input
          type="text"
          placeholder="Nom d'utilisateur"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <AnimatedButton className='mt-4' type="submit">Se connecter</AnimatedButton>
      </form>
      {error && <div className='text-xl text-red-500 py-4'>{error}</div>}
    </div>
  );
};

export default Login;
