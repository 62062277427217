// src/components/MainLayout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Heade from './Heade';
import Footer from './Footer';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <Heade />}
      {children}
      {!isAdminRoute && <Footer />}
    </>
  );
};

export default MainLayout;
