import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // useNavigate
import axios from 'axios';

const ProductAdmin = () => {
  const [products, setProducts] = useState([]);
  // const navigate = useNavigate();  // Use useNavigate instead of useHistory

  // Récupérer la liste des produits
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://boutiquewanted.com/api/products'); // L'URL doit correspondre à celle de votre API
        setProducts(response.data);
      } catch (error) {
        console.error('Erreur lors du chargement des produits:', error);
      }
    };

    fetchProducts();
  }, []);

  // Supprimer un produit
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://boutiquewanted.com/api/products/${id}`);
      setProducts(products.filter(product => product._id !== id)); // Supprimer le produit de l'état local
    } catch (error) {
      console.error('Erreur lors de la suppression du produit:', error);
    }
  };

  return (
    <div className="product-admin my-8 mb-24 px-4 sm:mx-32">
      <h1 className="flex lg:text-3xl text-lg font-semibold mb-8 text-yellow-800">
        Gestion des produits
        <Link
          to="/adminProduct/add"
          className="text-sm lg:text-lg bg-blue-500 text-white lg:p-3 p-1 rounded-md shadow-md hover:bg-blue-600 transition ml-auto"
        >
          Ajouter un produit
        </Link>  
      </h1>
      
      {/* Liste des produits */}
      <div className="product-list grid grid-cols-3 lg:grid-cols-1 lg:grid-cols-8 lg:gap-6 lg:gap-y-2">
        {products.length === 0 ? (
          <p className='pt-4'>Pas de Produit.</p>
        ) : (
          products.map((product) => (
            <div key={product.id} className="lg:w-40  max-h-56 lg:max-h-72 product-item p-2 lg:p-2 rounded-lg shadow-md">
              <img
                src={product.images[0]} // Affichage de la première image du produit
                alt={product.title}
                className="h-[70%] lg:h-[70%] w-full object-cover mb-2 rounded-2xl"
              />
              <h2 className="text-xs lg:text-sm font-semibold">{product.title}</h2>
              <p className="text-xs lg:text-sm text-gray-600">{product.price} DA</p>
              <div className="flex justify-between text-xs lg:text-base">
                {/* Modifier un produit */}
                <Link
                  to={`/adminProduct/edit/${product._id}`}
                  className="text-yellow-500 hover:text-yellow-700 transition"
                >
                  Modifier
                </Link>

                {/* Supprimer un produit */}
                <button
                  onClick={() => handleDelete(product._id)}
                  className="text-red-500 hover:text-red-700 transition"
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProductAdmin;
