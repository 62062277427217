// src/components/MainLayout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Heade from './HeadeAdmin';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AnimatedButton from './AnimatedButton';

const AdminLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminRoute = location.pathname.startsWith('/admin');

  const handleLogout = async () => {
    try {
      await axios.post(
        'https://boutiquewanted.com/api/authRoutes/logout',
        {},
        { withCredentials: true }
      );
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      console.error('Error during logout:', error.response?.data?.message || error.message);
    }
  };

  return (
    <>
      {isAdminRoute && (
        <header>
          <Heade />
          <AnimatedButton onClick={handleLogout} className='my-2 mb-4 px-4 mx-4 sm:mx-32'>Se déconnecter</AnimatedButton>
        </header>
      )}
      {children}
    </>
  );
};

export default AdminLayout;
