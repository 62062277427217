import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Camera } from 'lucide-react';
import imageCompression from 'browser-image-compression';

const subCategories = {
  'Vestes et Manteaux': ['Parka', 'Veste lègère', 'Manteau', 'Veste'],
  'Hauts': ['Chemise', 'Pull', 'Half Zipper', 'Sweat-Shirt', 'T-shirt'],
  'Jeans et Pantalons': [], // 'Jeans', 'Cargo', 'Pantalon', 'Short'
  'Chaussures': ['Bottes', 'Espadrine', 'Sandales', 'Mocassin', 'Chaussure'],
  'Ensemble': [],
};
/*
'Vestes et Manteaux': ['Jacket', 'Manteau'],
  'Vêtements du Haut': ['Surchemise', 'Pull', 'Half Zipper', 'Hoodies', 'T-shirt'],
  'Jeans et Pantalons': ['Jeans', 'Cargo', 'Pantalon', 'Short'],
  'Collection': ['Ensemble Oversize'],
  'Chaussures': ['Bottes', 'Espadrine'],
*/

const colorOptions = [
  // Blancs
  'blanc', 'blanc cassé', 'blanc crème', 'écru', 'ivoire',
  // Noirs
  'noir', 'noir charbon', 'noir intense',
  // Gris
  'gris', 'gris clair', 'gris souris', 'gris anthracite', 'gris perle', 'gris chiné', 'argent',
  // Bleus
  'bleu', 'bleu marine', 'bleu ciel', 'bleu royal', 'bleu nuit', 'bleu pétrole', 'bleu glacier', 'bleu indigo', 'turquoise',
  // Verts
  'vert', 'vert bouteille', 'vert militaire', 'vert sapin', 'vert olive', 'vert émeraude', 'vert pistache', 'vert menthe', 'vert kaki', 'vert sauge',
  // Marrons
  'marron', 'marron glacé', 'marron chocolat', 'camel', 'cognac', 'taupe', 'café', 'noisette',
  // Beiges
  'beige', 'beige clair', 'beige foncé', 'sable', 'chamois',
  // Rouges
  'rouge', 'bordeaux', 'rouge carmin', 'rouge brique', 'rouge cerise', 'corail',
  // Roses
  'rose', 'rose pâle', 'rose poudré', 'rose fuchsia', 'rose saumon',
  // Violets
  'violet', 'violet foncé', 'aubergine', 'prune', 'mauve', 'lilas',
  // Jaunes
  'jaune', 'jaune pâle', 'jaune moutarde', 'jaune safran', 'or',
  // Oranges
  'orange', 'orange brûlé', 'mandarine', 'abricot', 'pêche',
];
const sizeOptions = ['2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];
const sizeChaussure = ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45'];
const AddProduct = () => {
  const [productData, setProductData] = useState({
    title: '',
    marque: '',
    images: [''],
    price: 0,
    category: '',
    subCategory: '',
    description: '',
    colors: [],
    sizes: [],
    isNew: false,
    discount: 0,
  });
  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [error, setError] = useState(null);
  const [isCompressing, setIsCompressing] = useState(false);

  const compressionOptions = {
    maxSizeMB: 0.3, // Taille maximum en MB
    maxWidthOrHeight: 1200, // Dimension maximum
    useWebWorker: true, // Utilise un Web Worker pour ne pas bloquer l'UI
    initialQuality: 1, // Qualité initiale (0 à 1)
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePrice = (e) => {
    const value = e.target.value;
    setProductData((prevData) => ({
      ...prevData,
      price: parseFloat(value) || 0,
    }));
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setProductData((prevState) => ({
      ...prevState,
      category,
      subCategory: '',
    }));
  };

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setIsCompressing(true);
        setError(null);
  
        if (!file.type.startsWith('image/')) {
          throw new Error('Please select an image file');
        }
  
        if (file.size > 10 * 1024 * 1024) { // 10MB limit
          throw new Error('File is too large. Please select an image under 10MB');
        }
  
        const compressedFile = await compressImage(file); // Assuming compressImage is already correct
  
        // Upload the compressed file to Cloudinary
        const formData = new FormData();
        formData.append('image', compressedFile);
  
        const response = await axios.post('https://boutiquewanted.com/api/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
  
        if (!response.data || !response.data.imageUrl) {
          throw new Error('Failed to upload image to Cloudinary');
        }
  
        const imageUrl = response.data.imageUrl;
  
        // Update images array with Cloudinary image URL
        const updatedImages = [...productData.images];
        updatedImages[index] = imageUrl;
        setProductData(prev => ({
          ...prev,
          images: updatedImages
        }));
  
        const reader = new FileReader();
        reader.onload = (event) => {
          const newPreviews = [...imagesPreviews];
          newPreviews[index] = event.target.result;
          setImagesPreviews(newPreviews);
        };
        reader.readAsDataURL(compressedFile);
  
      } catch (error) {
        console.error('Error processing image:', error);
        setError(error.message || 'Error processing image');
      } finally {
        setIsCompressing(false);
      }
    }
  };
  
  const handleAddImageField = () => {
    setProductData(prev => ({
      ...prev,
      images: [...prev.images, '']
    }));
    setImagesPreviews(prev => [...prev, '']);
  };

  const removeImage = (index) => {
    setProductData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
    setImagesPreviews(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      if (!productData.title || !productData.price || !productData.category) {
        throw new Error('Please fill in all required fields');
      }
  
      if (!productData.images[0]) {
        throw new Error('Please select at least one image');
      }
  
      // The images are already uploaded to Cloudinary, you don't need to re-upload them
      const finalProductData = {
        ...productData,
        images: productData.images.filter(Boolean) // Cloudinary URLs are already in productData
      };
  
      const response = await axios.post('https://boutiquewanted.com/api/products', finalProductData);
      
      if (response.status === 201) {
        navigate('/adminProduct');
      } else {
        throw new Error('Failed to create product');
      }
    } catch (error) {
      setError(error.message || 'Error adding product');
      console.error('Submit error:', error);
    }
  };
  

  const handleCheckboxChange = (e, type) => {
    const { value, checked } = e.target;
    setProductData((prevData) => {
      const updatedArray = checked
        ? [...prevData[type], value]
        : prevData[type].filter((item) => item !== value);

      return {
        ...prevData,
        [type]: updatedArray,
      };
    });
  };

  async function compressImage(file) {
    try {
      // Vérifier si le fichier est une image
      if (!file || !file.type.startsWith('image/')) {
        throw new Error('Le fichier doit être une image');
      }
  
      //console.log('Taille originale:', (file.size / 1024 / 1024).toFixed(2), 'MB');
  
      // Compression de l'image
      const compressedFile = await imageCompression(file, compressionOptions);
  
      //console.log('Taille après compression:', (compressedFile.size / 1024 / 1024).toFixed(2), 'MB');
  
      return compressedFile;
    } catch (error) {
      console.error('Erreur lors de la compression:', error);
      throw error;
    }
  }

  return (
    <div className="add-product my-8 mb-24 px-4 sm:mx-32">
      <h1 className="text-3xl font-semibold bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent mb-8">
        Ajouter un nouveau produit
      </h1>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="title" className="block text-lg">Titre du produit</label>
          <input
            type="text"
            id="title"
            name="title"
            value={productData.title}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="marque" className="block text-lg">Marque</label>
          <input
            type="text"
            id="marque"
            name="marque"
            value={productData.marque}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="price" className="block text-lg">Prix</label>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price}
            onChange={handleChangePrice}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="category" className="block text-lg">Catégorie</label>
          <select
            id="category"
            name="category"
            value={productData.category}
            onChange={handleCategoryChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Sélectionner une catégorie</option>
            {Object.keys(subCategories).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        {(subCategories !== 'Ensemble' && subCategories !== 'Jeans et Pantalons' && <div>
          <label htmlFor="subCategory" className="block text-lg">Sous-catégorie</label>
          <select
            id="subCategory"
            name="subCategory"
            value={productData.subCategory}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
            disabled={!productData.category}
          >
            <option value="">Sélectionner une sous-catégorie</option>
            {productData.category &&
              subCategories[productData.category].map((subCategory) => (
                <option key={subCategory} value={subCategory}>
                  {subCategory}
                </option>
              ))}
          </select>
        </div>
        )}
        <div>
          <label htmlFor="description" className="block text-lg">Description</label>
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        {/* <div>
          <label htmlFor="images" className="block text-lg">Images (URLs)</label>
          {productData.images.map((image, index) => (
            <div key={index} className="mb-3">
              <input
                type="file"
                onChange={(e) => handleImageChange(e, index)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddImageField}
            className="mt-3 bg-blue-500 text-white p-2 rounded-md"
          >
            Ajouter une image
          </button>
        </div> */}


{/* Section upload d'images */}
      <div className="mb-6">
        <label className="block text-lg mb-4 font-medium">Images du produit</label>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {productData.images.map((_, index) => (
            <div key={index} className="relative">
              <div className="flex flex-col items-center border-2 border-dashed border-gray-300 rounded-lg p-4">
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e, index)}
                  accept="image/*"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  disabled={isCompressing}
                  aria-label="Sélectionner une image"
                />
                
                {imagesPreviews[index] ? (
                  <div className="relative w-full">
                    <img 
                      src={imagesPreviews[index]} 
                      alt={`Aperçu ${index + 1}`} 
                      className="w-full h-48 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => removeImage(index)}
                      className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
                      type="button"
                      disabled={isCompressing}
                    >
                      ✕
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-8">
                    {isCompressing ? (
                      <div className="text-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2"></div>
                        <p className="text-sm text-gray-500">Compression en cours...</p>
                      </div>
                    ) : (
                      <>
                        <Camera className="w-12 h-12 text-gray-400 mb-2" />
                        <p className="text-sm text-gray-500">
                          Appuyez pour ajouter une photo
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {productData.images.length < 8 && (
          <button
            type="button"
            onClick={handleAddImageField}
            className="w-full mt-3 bg-blue-500 text-white p-3 rounded-lg font-medium hover:bg-blue-600 transition-colors"
            disabled={isCompressing}
          >
            Ajouter une image
          </button>
        )}
      </div>


        <div>
          <label className="block text-lg">Couleurs</label>
          <div className="flex flex-wrap">
            {colorOptions.map((color) => (
              <label key={color} className="p-2 flex">
                <input
                  type="checkbox"
                  value={color}
                  checked={productData.colors.includes(color)}
                  onChange={(e) => handleCheckboxChange(e, 'colors')}
                />
                <p className='ml-1'>{color}</p>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-lg">Tailles</label>
          <div className="flex flex-wrap">
            {productData.category === 'Chaussures'
              ? sizeChaussure.map((size) => (
                  <label key={size} className="p-2 flex">
                    <input
                      type="checkbox"
                      value={size}
                      checked={productData.sizes.includes(size)}
                      onChange={(e) => handleCheckboxChange(e, 'sizes')}
                    />
                    <p className='ml-1'>{size}</p>
                  </label>
                ))
              : sizeOptions.map((size) => (
                  <label key={size} className="p-2 flex">
                    <input
                      type="checkbox"
                      value={size}
                      checked={productData.sizes.includes(size)}
                      onChange={(e) => handleCheckboxChange(e, 'sizes')}
                    />
                    <p className='ml-1'>{size}</p>
                  </label>
                ))}
          </div>
        </div>

        <div>
          <label htmlFor="isNew" className="inline-flex items-center">
            <span className="text-lg">Produit neuf</span>
            <input
              type="checkbox"
              id="isNew"
              name="isNew"
              checked={productData.isNew}
              onChange={(e) => setProductData({ ...productData, isNew: e.target.checked })}
              className="ml-3"
            />
          </label>
        </div>
        <div className="flex">
          <label htmlFor="discount" className="block text-lg py-1">Remise (%)</label>
          <input
            type="number"
            id="discount"
            name="discount"
            value={productData.discount}
            onChange={handleChange}
            className="w-[92%] p-2 border border-gray-300 rounded-md ml-auto"
          />
        </div>

        <div className="mt-4">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md"
          >
            Ajouter le produit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
