import React from 'react';

const AnimatedButton = ({ children, className = '', onClick }) => {
  return (
    <button 
      onClick={onClick}
      className={`
      group relative overflow-hidden
      bg-black hover:bg-black/90
      text-white font-semibold xs:text-md lg:text-lg
      lg:px-6 px-3 lg:py-2 py-1.5 rounded-md
      transition-all duration-300
      ${className}
    `}>
      <span className="absolute inset-0 border-2 border-yellow-500 rounded-md opacity-0 group-hover:opacity-100 transition-all duration-500" />
      <span className="absolute inset-0 bg-gradient-to-r from-yellow-500/20 to-transparent opacity-0 group-hover:opacity-100 animate-shine" />
      <span className="relative">{children}</span>
    </button>
  );
};

export default AnimatedButton;