// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../features/cartSlice'; // Adjust the path based on your structure

const store = configureStore({
  reducer: {
    cart: cartSlice,
  },
});

export default store;
