import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AdminHome = () => {

  const [totalOrders, setTotalOrders] = useState(0);
  const [confirmedOrders, setConfirmedOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  // Fonction pour récupérer les données des API
  const fetchData = async () => {
    try {
      // Récupérer les produits
      const productsResponse = await axios.get('https://boutiquewanted.com/api/products'); // Mettez l'URL de votre API de produits
      setTotalProducts(productsResponse.data.length);

      // Récupérer les commandes
      const ordersResponse = await axios.get('https://boutiquewanted.com/api/orders'); // Mettez l'URL de votre API de commandes
      setTotalOrders(ordersResponse.data.length);

      // Compter les commandes confirmées
      const confirmedOrdersCount = ordersResponse.data.filter(order => order.state === 'confirmed').length;
      setConfirmedOrders(confirmedOrdersCount);

    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  // Utiliser useEffect pour appeler fetchData au montage du composant
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="admin-home my-8 mb-24 px-4 sm:mx-32">
      <h1 className="text-3xl font-semibold mb-6">Tableau de bord de l'administration</h1>

      <div className="stats grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {/* Statistiques */}
        <div className="stat bg-white p-6 py-10 rounded-lg shadow-2xl">
          <h2 className="text-xl font-semibold mb-3">Total de commandes</h2>
          <p className="text-4xl font-bold text-center">{totalOrders}</p>
        </div>

        <div className="stat bg-white p-6 py-10 rounded-lg shadow-2xl">
          <h2 className="text-xl font-semibold mb-3">Commandes Confirmés</h2>
          <p className="text-4xl font-bold text-center">{confirmedOrders}</p>
        </div>

        <div className="stat bg-white p-6 py-10 rounded-lg shadow-2xl">
          <h2 className="text-xl font-semibold mb-3">Total des produits</h2>
          <p className="text-4xl font-bold text-center">{totalProducts}</p>
        </div>

      </div>

      <div className="actions grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        {/* Liens vers la gestion des produits */}
        <Link
          to="/adminProduct"
          className="action-item bg-blue-500 text-white p-6 rounded-lg shadow-lg text-center hover:bg-blue-600 transition"
        >
          <h2 className="text-xl font-semibold mb-3">Gestion des produits</h2>
          <p className="text-lg">Ajouter, modifier ou supprimer des produits</p>
        </Link>

        {/* Liens vers la gestion des commandes */}
        <Link
          to="/adminOrder"
          className="action-item bg-green-500 text-white p-6 rounded-lg shadow-lg text-center hover:bg-green-600 transition"
        >
          <h2 className="text-xl font-semibold mb-3">Gestion des commandes</h2>
          <p className="text-lg">Voir et gérer les commandes clients</p>
        </Link>        
      </div>
    </div>
  );
};

export default AdminHome;
