import React from 'react';
import { Link } from 'react-router-dom';
import AnimatedButton from './AnimatedButton';

const Header = () => {
  return (
    <header className="bg-white lg:px-32 lg:py-6 py-5 px-5 flex justify-between items-center sticky top-0 z-50 lg:mr-0">
      <h1 className="text-xl lg:text-2xl font-bold flex items-center">
        Boutique Wanted
        <img 
          src="/logo.jpg" 
          alt="Wanted Logo"
          className="h-6 ml-2 w-auto rounded-full" 
        />
      </h1>

      <nav className="lg:space-x-3 space-x-2 lg:text-lg lg:font-medium">
        <Link to="/cart" className="hover:text-gray-600">
          <AnimatedButton>السلة</AnimatedButton>
        </Link>
        <Link to="/" className="hover:text-gray-600">
          <AnimatedButton>المتجر</AnimatedButton>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
